import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  Observable,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
import { print } from "graphql/language/printer";
import { Presence, Socket as PhoenixSocket } from "phoenix";
// import noop from 'lodash/noop';
import i18n from "i18n-js";
import {
  startCustomerAccountSubscription,
  startCustomerContactSubscription,
  startUserCreatedOrUpdatedSubscription,
  // startOpenThreadForUserSubscription,
  startClosedThreadsSubscription,
  startGlobalSnackbarSubscription,
} from "./setupSubscriptions";
import "cross-fetch/polyfill";
import userInfoFragment from "../../graphql/fragments/UserInfoFragment.graphql";
import i18nTranslations from "../../i18n";
import { createFuzzIntervalMs } from "../../utils/helpers";

i18n.translations = {
  "en-US": { ...i18nTranslations.EN_US },
  "es-MX": { ...i18nTranslations.ES_MX },
  "fr-CA": { ...i18nTranslations.FR_CA },
};

// This is optional but highly recommended
// since it prevents memory leak

const globalWindow = window || global;

let API_URL = globalWindow?.location
  ? `${globalWindow.location.protocol}//${globalWindow.location.host}/api`
  : "";
const WS_URL = globalWindow?.location
  ? `wss://${globalWindow.location.host}/socket`
  : "";

// temporary redux logic that this apollo config needs

export const AUTH_SIGNOUT = "AUTH_SIGNOUT";
export const RESET_STORE = "RESET_STORE";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export const AUTH_SIGNIN = "AUTH_SIGNIN";
export const UPDATE_APPLICATION_STATE = "UPDATE_APPLICATION_STATE";

const FINAL_RECONNECT_INT = createFuzzIntervalMs(5000, 10000);
const FUZZ_INT = createFuzzIntervalMs(8, 12);
const FUZZ_INT_EXP = FUZZ_INT * FUZZ_INT;
const RECONNECT_INTS_FUZZED = [
  FUZZ_INT,
  FUZZ_INT * 2,
  FUZZ_INT_EXP,
  FUZZ_INT_EXP * 2,
  FUZZ_INT_EXP * 4,
  FUZZ_INT_EXP * 6,
  FUZZ_INT_EXP * 8,
  FUZZ_INT_EXP * 10,
  FUZZ_INT_EXP * 20,
];

export const signOut = () => ({
  type: AUTH_SIGNOUT,
});
export const resetStore = () => ({
  type: RESET_STORE,
});
export const setUserSettings = (payload) => ({
  type: SET_USER_SETTINGS,
  payload,
});
export const authSignIn = (sessionUser) => ({
  type: AUTH_SIGNIN,
  userId: sessionUser.userId || sessionUser.id,
  username: sessionUser.username,
  contactId: sessionUser.contactId,
  accountId: sessionUser.accountId,
  groupIds: sessionUser.groupIds,
  accountPolicies: sessionUser.accountPolicies || [],
  groupPolicies: sessionUser.groupPolicies || [],
  firstName: sessionUser.firstName,
  lastName: sessionUser.lastName,
  role: sessionUser.role,
  stockMessagesConfig:
    sessionUser.stockMessagesConfig || sessionUser.stockMessages,
  notificationInterval: sessionUser.notificationInterval,
  notificationSound: sessionUser.notificationSound,
  notificationSettingsLocked: sessionUser.notificationSettingsLocked,
  groupInboxNotificationsEnabled: sessionUser.groupInboxNotificationsEnabled,
  announcementsAccess: sessionUser.announcementsAccess,
  theme: sessionUser.theme,
  emailNotificationsEnabled: sessionUser.emailNotificationsEnabled,
  limitEmailNotifications: sessionUser.limitEmailNotifications,
  emailNotificationDelayMins: sessionUser.emailNotificationDelayMins,
  unclaimedThreadEmailNotificationsEnabled:
    sessionUser.unclaimedThreadEmailNotificationsEnabled,
  unreadThreadEmailNotificationsEnabled:
    sessionUser.unreadThreadEmailNotificationsEnabled,
  unclaimedThreadMobileNotificationsEnabled:
    sessionUser.unclaimedThreadMobileNotificationsEnabled,
  unreadThreadMobileNotificationsEnabled:
    sessionUser.unreadThreadMobileNotificationsEnabled,
  avatarUrl: sessionUser.avatarUrl,
  language: sessionUser.language,
  title: sessionUser.title,
  fontSize: sessionUser.fontSize,
  transferThreadNotification: sessionUser.transferThreadNotification,
});
export const updateApplicationState = (applicationState) => ({
  type: UPDATE_APPLICATION_STATE,
  applicationState,
});

// -----

const runQuery = (operationName, query, apiUrl = API_URL) =>
  new Promise((resolve, reject) => {
    const xhr = new globalWindow.XMLHttpRequest();
    xhr.responseType = "json";
    xhr.open("POST", apiUrl);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onload = () => resolve(xhr);
    xhr.ontimeout = () => reject(xhr);
    xhr.onerror = () => reject(xhr);
    xhr.send(JSON.stringify({ operationName, query }));
  });

const createConnectTokenMutation = (apiUrl = API_URL) => {
  const operationName = "createConnectTokenMutation";
  const query = `mutation createConnectTokenMutation {
    createConnectToken {
      token
      errors {
        reason
        field
      }
    }
  }`;

  return runQuery(operationName, query, apiUrl);
};

class AbsintheLink extends ApolloLink {
  constructor({
    uri,
    userId,
    accountId,
    token,
    Sentry,
    getNetworkInformation,
  }) {
    super();

    this._uri = uri;
    this._socket = null;
    this._absintheChannel = null;
    this._presenceChannel = null;
    this._presencePresence = null;
    this._userId = userId;
    this._accountId = accountId;
    this._token = token;
    this._queue = [];
    this._subscriptions = [];
    this._presenceJoinCallbacks = [];
    this._socketOpenCallbacks = [];
    this._socketCloseCallbacks = [];
    this._socketErrorCallbacks = [];
    this._authErrorCallbacks = [];
    this._reconnectTries = 0;
    this._apolloClient = null;
    this._reconnectAfterMs = (tries) =>
      RECONNECT_INTS_FUZZED[tries - 1] || FINAL_RECONNECT_INT;
    this._isSignOut = null;
    this.Sentry = Sentry;
    this.getNetworkInformation = getNetworkInformation;
  }

  setApolloClient(client) {
    this._apolloClient = client;
  }

  initSocket() {
    this._socket = new PhoenixSocket(this._uri, {
      params: {
        device_id: Date.now(),
        token: this._token,
        client_version: process.env.VERSION,
      }, // eslint-disable-line no-undef
      heartbeatIntervalMs: 15000,
      timeout: 75000,
    });

    this._socket.onOpen(async () => {
      console.log("Websocket connection established.");

      this._subscriptions = [];
      const initialConnect = this._absintheChannel === null;

      if (this._absintheChannel === null) {
        this._absintheChannel = this._socket.channel(
          "__absinthe__:control",
          {}
        );
        this._absintheChannel.join().receive("ok", () => {
          while (this._queue.length > 0) {
            const item = this._queue.shift();

            this.pushRequest(item.request, item.observer);
          }
        });
      }

      if (this._presenceChannel === null) {
        this._presenceChannel = this._socket.channel(
          `presence:${this._accountId}`,
          {}
        );
        this._presencePresence = new Presence(this._presenceChannel);

        const setOnlineStatus = async (userId, online, updateTime) => {
          const user = this._apolloClient.readFragment({
            id: "User_" + userId,
            fragment: userInfoFragment,
          });

          if (!user) {
            const data = {
              id: userId,
              __typename: "User",
              accountId: this._accountId,
              groupIds: [],
              defaultGroupId: null,
              contactId: null,
              enabled: null,
              role: null,
              username: null,
              firstName: null,
              lastName: null,
              verificationStatus: null,
              notificationSettingsLocked: null,
              emailNotificationsEnabled: null,
              limitEmailNotifications: null,
              emailNotificationDelayMins: null,
              unclaimedThreadEmailNotificationsEnabled: null,
              unreadThreadEmailNotificationsEnabled: null,
              unclaimedThreadMobileNotificationsEnabled: null,
              unreadThreadMobileNotificationsEnabled: null,
              avatarUrl: null,
              announcementsAccess: null,
              presenceUpdateTime: updateTime,
              online,
              title: null,
              canCreatePaymentRequests: false,
              canEditTitle: false,
            };

            this._apolloClient.writeFragment({
              id: "User_" + userId,
              fragment: userInfoFragment,
              data,
            });
          } else if (
            user.presenceUpdateTime < updateTime ||
            !user.presenceUpdateTime
          ) {
            const mutableUser = { ...user };
            mutableUser.online = online;
            mutableUser.presenceUpdateTime = updateTime;

            this._apolloClient.writeFragment({
              id: "User_" + userId,
              fragment: userInfoFragment,
              data: mutableUser,
            });
          }
        };

        this._presenceChannel.on("presence_diff", (msg) => {
          Object.keys(msg.leaves).forEach((userId) => {
            msg.leaves[userId].metas.forEach((meta) => {
              setOnlineStatus(userId, meta.online, meta.state_time);
            });
          });

          Object.keys(msg.joins).forEach((userId) => {
            msg.joins[userId].metas.forEach((meta) => {
              setOnlineStatus(userId, meta.online, meta.state_time);
            });
          });
        });

        this._presenceChannel
          .join()
          .receive("ok", (response) => {
            this._presenceJoinCallbacks.forEach((callback) => {
              callback(response);
            });
          })
          .receive("error", async (response) => {
            const networkInfo = await this.getNetworkInformation();

            this.Sentry.captureMessage(
              "Presence Channel Join failed with error",
              {
                extra: { response, networkInfo },
                level: "error",
              }
            );

            this._presenceJoinCallbacks.forEach((callback) => {
              callback(response);
            });
          })
          .receive("timeout", async () => {
            const networkInfo = await this.getNetworkInformation();

            this.Sentry.captureMessage(
              "Presence Channel Join Timeout Failure",
              {
                extra: { networkInfo },
                level: "error",
              }
            );
            console.log("Presence Channel Join Timeout Failure");
          });
      }

      this._socketOpenCallbacks.forEach((callback) => {
        callback(initialConnect);
      });

      if (window?.addEventListener) {
        window?.addEventListener("beforeunload", () => {
          this._presenceChannel.leave();
        });
      }
    });

    this._socket.onMessage(async (message) => {
      if (message.event === "subscription:data") {
        const subscription = this._subscriptions.find(
          (s) => s.subscriptionId === message.payload.subscriptionId
        );

        if (subscription && subscription.observer) {
          subscription.observer.next(message.payload.result);
        } else {
          const networkInfo = await this.getNetworkInformation();
          const subscriptionNames = this._subscriptions.map(
            (s) => s.request.operationName
          );

          this.Sentry.captureMessage(
            "Subscription does not exist on websocket message",
            {
              extra: {
                subsOnMessage: subscriptionNames,
                isConnected: this._socket.isConnected(),
                userId: this._userId,
                networkInfo,
                accountId: this._accountId,
              },
              level: "error",
            }
          );
        }
      }
    });

    this._socket.onClose(async (e) => {
      console.log("Websocket closed.");
      this._socket.disconnect();
      this._socket.reconnectTimer.reset();

      if (e.wasClean) return;

      this._reconnectTries += 1;

      setTimeout(() => {
        this.connect();
      }, this._reconnectAfterMs(this._reconnectTries));

      this._socketCloseCallbacks.forEach((callback) => {
        callback();
      });
    });
  }

  pushUnsubscribe(subscriptionId) {
    // this method is used to notify the BE that a subscription has stopped on our end, and that they can close
    // said subscription on theirs.
    if (subscriptionId) {
      try {
        this._absintheChannel.push("unsubscribe", { subscriptionId });
      } catch (e) {
        console.warn("Unsubscribe Push Failure ", e);
      }
    }
  }

  request(operation) {
    return new Observable((observer) => {
      const request = {
        ...operation,
        query: print(operation.query),
      };

      const requestData = this.pushRequest(request, observer);

      return () => {
        // returning a callback for the observable to use when the subscription stops.
        if (requestData?.subscriptionId) {
          const { subscriptionId } = requestData;
          this.pushUnsubscribe(subscriptionId);
        }
      };
    });
  }

  pushRequest(request, observer) {
    const requestData = { subscriptionId: null };
    if (this._absintheChannel) {
      // If is subscription, then check if we already have this subscription
      // setup. Need to check both operation name and variables are the same.
      const isSubscription = request.query.startsWith("subscription");

      // finding index of subscription if it already exists as we need it if we want to resubscribe
      const existsIndex =
        isSubscription &&
        this._subscriptions.findIndex((s) => {
          if (request.operationName === s.request.operationName) {
            return Object.keys(request.variables).every(
              (k) => request.variables[k] === s.request.variables[k]
            );
          }

          return false;
        });

      if (isSubscription) {
        // find if subscription status is currently closed
        const closed =
          existsIndex >= 0
            ? this._subscriptions[existsIndex].observer._subscription._state ===
              "closed"
            : false;

        // if exists AND closed go no further
        if (existsIndex >= 0 && !closed) {
          observer.complete();
          return requestData;
        }

        // either place new subscription in this._subscriptions or renew closed subscription at correct index
        if (existsIndex >= 0 && closed) {
          this._subscriptions[existsIndex] = {
            subscriptionId: null,
            request,
            observer,
          };
        } else {
          this._subscriptions.push({ subscriptionId: null, request, observer });
        }

        const currentSubs = JSON.stringify(this._subscriptions);
        this._absintheChannel
          .push("doc", request)
          .receive("ok", async (res) => {
            if (res.subscriptionId !== undefined) {
              const subscription = this._subscriptions.find(
                (s) => s.request === request && s.observer === observer
              );
              if (!subscription) {
                const networkInfo = await this.getNetworkInformation();
                const subscriptionNames = this._subscriptions.map(
                  (s) => s.request.operationName
                );

                this.Sentry.captureMessage(
                  "Subscription does not exist on request received",
                  {
                    extra: {
                      request,
                      subsBeforePushRequest: currentSubs,
                      subsAfterPushRequest: subscriptionNames,
                      isConnected: this._socket.isConnected(),
                      userId: this._userId,
                      networkInfo,
                      accountId: this._accountId,
                    },
                    level: "error",
                  }
                );
              } else {
                subscription.subscriptionId = res.subscriptionId;
                requestData.subscriptionId = res.subscriptionId;
              }
            } else {
              observer.next(res);
              observer.complete();
            }
          })
          .receive("ignore", async (res) => {
            const networkInfo = await this.getNetworkInformation();
            const subscriptionNames = this._subscriptions.map(
              (s) => s.request.operationName
            );

            this.Sentry.captureMessage(
              "Push request - absinthe channel - received ignore",
              {
                extra: {
                  request,
                  receivedResponse: res,
                  subsBeforePushRequest: currentSubs,
                  subsAfterPushRequest: subscriptionNames,
                  isConnected: this._socket.isConnected(),
                  userId: this._userId,
                  networkInfo,
                  accountId: this._accountId,
                },
                level: "warning",
              }
            );

            observer.complete();
          })
          .receive("error", async () => {
            observer.error.bind(observer);
          })
          .receive("timeout", () => {
            observer.error.bind(observer);
          });
      }
    } else {
      this._queue.push({ request, observer });
    }

    return requestData;
  }

  onPresenceJoin(callback) {
    this._presenceJoinCallbacks.push(callback);
  }

  onSocketOpen(callback) {
    this._socketOpenCallbacks.push(callback);
  }

  async onSocketError(callback) {
    this._socketErrorCallbacks.push(callback);

    const networkInfo = await this.getNetworkInformation();
    const subscriptionNames = this._subscriptions.map(
      (s) => s.request.operationName
    );

    this.Sentry.captureMessage("Create Apollo Client - onSocketError event", {
      extra: {
        subsOnMessage: subscriptionNames,
        isConnected: this._socket.isConnected(),
        userId: this._userId,
        networkInfo,
        accountId: this._accountId,
      },
      level: "error",
    });
  }

  onSocketClose(callback) {
    this._socketCloseCallbacks.push(callback);
  }

  onAuthError(callback) {
    this._authErrorCallbacks.push(callback);
  }

  setUserId(userId) {
    this._userId = userId;
  }

  setAccountId(accountId) {
    this._accountId = accountId;
  }

  setUri(uri) {
    this._uri = uri;
  }

  setToken(token) {
    this._token = token;

    if (this._socket !== null) {
      const socketParams = this._socket.params();
      this._socket.params = () => ({ ...socketParams, token });
    }
  }

  connect(apiUrl = API_URL, cb = () => {}) {
    createConnectTokenMutation(apiUrl)
      .then((xhr) => {
        switch (xhr.status) {
          case 200:
            // Check if user is not authenticated (ie. no cookie or invalid token in cookie).
            if (
              xhr.response.errors &&
              xhr.response.errors.length > 0 &&
              xhr.response.errors[0].message === "authentication_required"
            ) {
              console.error(
                "Could not get connect token: authentication_required 200."
              );
              this._authErrorCallbacks.forEach((callback) => {
                callback();
              });

              break;
            }

            if (xhr.response.data && xhr.response.data.createConnectToken) {
              this.setToken(xhr.response.data.createConnectToken.token);
            }

            if (this._socket === null) {
              this.initSocket();
            }

            this._reconnectTries = 0;

            cb();

            // reset isSignOut from last logout
            this._isSignOut = null;

            console.log(
              "Received connect token, attempting to establish websocket connection."
            );

            this._socket.connect();

            break;
          case 401:
            console.error(
              "Could not get connect token: authentication_required 401."
            );

            this._authErrorCallbacks.forEach((callback) => {
              callback();
            });

            break;
          default:
            this._reconnectTries += 1;

            setTimeout(() => {
              this.connect();
            }, this._reconnectAfterMs(this._reconnectTries));

            break;
        }
      })
      .catch(async () => {
        console.error(
          "A network error prevented websocket connection, retrying..."
        );

        this._reconnectTries += 1;

        setTimeout(() => {
          this.connect();
        }, this._reconnectAfterMs(this._reconnectTries));
      });
  }

  disconnect(isSignOut = null) {
    if (this._absintheChannel) {
      this._absintheChannel.leave();
      this._absintheChannel = null;
    }

    if (this._presenceChannel) {
      this._presenceChannel.leave();
      this._presenceChannel = null;
    }

    if (this._socket) {
      this._socket.disconnect();
      this._socket.reconnectTimer.reset();
    }

    if (isSignOut) {
      this._isSignOut = true;
    }
    // else {
    // TODO leave this one off for now and evaluate usefulness
    // const networkInfo = this.getNetworkInformation();
    // const subscriptionNames = this._subscriptions.map(
    //   (s) => s.request.operationName
    // );
    // this.Sentry.captureMessage(
    //   "Create Apollo Client - disconnect event - not isSignOut",
    //   {
    //     extra: {
    //       subsOnMessage: subscriptionNames,
    //       isConnected: this._socket.isConnected(),
    //       userId: this._userId,
    //       networkInfo,
    //       accountId: this._accountId,
    //     },
    //     level: "warning",
    //   }
    // );
    // }
  }
}

const hasSubscription = (documentNode) =>
  documentNode.definitions.some(
    (definition) =>
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
  );

const createApolloClient = (store, Sentry, getNetworkInformation) => {
  const currentUser = store.getState().session.currentUser;
  const account = store.getState()?.accountData?.account;
  const activeGroupIds = [];
  const isMobile = process.env.PLATFORM === "mobile";

  const locale = store.getState().session.currentUser?.language || "EN_US";

  const formattedLocale =
    locale.slice(0, 2).toLowerCase() + "-" + locale.slice(3);
  i18n.locale = formattedLocale;
  i18n.fallbacks = "en-US";

  const httpLink = createHttpLink({ uri: "/api" });

  const httpMobileLink = setContext(() => {
    const mobileApi = store?.getState()?.domain?.api;

    if (mobileApi) {
      return {
        uri: mobileApi,
      };
    }

    return { uri: API_URL };
  });

  const headersLink = setContext(() => ({
    headers: {
      "Screen-Width": globalWindow.outerWidth,
      "Screen-Height": globalWindow.outerHeight,
      "Active-Group-Id": store.getState().session.threadsActiveGroupIds?.[0],
    },
  }));

  const httpLinkWithHeaders = headersLink.concat(httpLink);

  const absintheLink = new AbsintheLink({
    uri: WS_URL,
    userId: currentUser && currentUser.userId,
    accountId: currentUser && currentUser.accountId,
    Sentry,
    getNetworkInformation,
  });

  const link = ApolloLink.split(
    (operation) => hasSubscription(operation.query),
    absintheLink,
    httpLinkWithHeaders
  );

  const conditionalRelayStylePagination = (keyArgs = false) => ({
    keyArgs,
    merge(existing, incoming, info) {
      if (info?.variables?.relayStylePagination) {
        return relayStylePagination().merge(existing, incoming, info);
      }
      return incoming;
    },
  });

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([httpMobileLink, link]),
    cache: new InMemoryCache({
      typePolicies: {
        CustomerContact: {
          fields: {
            labels: {
              merge(_, incoming) {
                return incoming;
              },
            },
          },
        },
        Query: {
          fields: {
            announcements: conditionalRelayStylePagination(["first", "status"]),
            automatedRules: {
              keyArgs: false,
            },
            customerContactThreadsHistory: relayStylePagination(),
            customersByCompany: {
              keyArgs: ["companyId"],
            },
            customerAccountThreadsHistory: relayStylePagination(),
            internalThreadHistory: relayStylePagination(),
            groupInternalThreadHistory: relayStylePagination(),
            customers: conditionalRelayStylePagination(["optOutStatus"]),
            recentCustomers: relayStylePagination(),
            users: conditionalRelayStylePagination(["groupIds", "filter"]),
            team: relayStylePagination(),
            customersWithTagReference: relayStylePagination(),
            groupsWithTagReferencePaginated: relayStylePagination(),
            directTagCustomers: relayStylePagination(),
            groupsPaginated: conditionalRelayStylePagination(),
            regionsAndGroups: conditionalRelayStylePagination(),
            groupsV2: conditionalRelayStylePagination(),
            tagsV2: relayStylePagination(),
            announcementRecipients: conditionalRelayStylePagination(),
            exportAnnouncementQuery: conditionalRelayStylePagination(),
            tags: {
              keyArgs: false,
            },
            listTemporaryHours: {
              keyArgs: false,
            },
            myInboxThreads: relayStylePagination(),
            myOpenThreads: relayStylePagination(),
            allOpenThreads: relayStylePagination(),
            myClosedThreads: relayStylePagination(),
            allClosedThreads: relayStylePagination(),
            inboxSpamThreads: relayStylePagination(),
            tagAudience: relayStylePagination(["tagId", "optOutStatus"]),
            workOrders: relayStylePagination(),
            feedbackResponses: conditionalRelayStylePagination(),
            applications: {
              keyArgs: false,
            },
            tasks: conditionalRelayStylePagination(),
            customersWithAnnouncementReference: relayStylePagination(),
            groupsWithAnnouncementReferencePaginated: relayStylePagination(),
            tagsWithAnnouncementReference: relayStylePagination(),
            directAnnouncementCustomers: relayStylePagination(),
            directAnnouncementTags: relayStylePagination(),
            announcementAudience: relayStylePagination(["optOutStatus"]),
            searchCustomers: conditionalRelayStylePagination(),
            searchThreads: conditionalRelayStylePagination(),
            searchFaxContacts: relayStylePagination(),
            usersGroupsPaginated: relayStylePagination(),
            transferTargets: relayStylePagination(),
            activities: relayStylePagination(["contactIds"]),
            templates: conditionalRelayStylePagination(),
            contacts: relayStylePagination([
              "notWithCompanyIds",
              "audienceBuilder",
            ]),
            regions: { keyArgs: false },
            blockedChannels: conditionalRelayStylePagination(),
            blockedContacts: conditionalRelayStylePagination(),
            listTagAudienceInfo: relayStylePagination(),
            selectedAudience: relayStylePagination(),
            reachableAudience: relayStylePagination(),
            labels: conditionalRelayStylePagination(),
            announcementRecurringSchedule: relayStylePagination(),
          },
        },
      },
      possibleTypes: {
        Customer: ["CustomerAccount", "CustomerContact"],
        MessagingContact: ["CustomerContact", "UserContact"],
      },
      dataIdFromObject: (o) =>
        o?.id
          ? `${o.__typename}_${o.id}`
          : o?.cursor
            ? `${o.__typename}_${o.cursor}`
            : null,
    }),
    connectToDevTools: true,
  });

  absintheLink.setApolloClient(apolloClient);
  apolloClient.activeGroupIds = () => activeGroupIds;

  apolloClient.getConnectToken = () => absintheLink._token;

  apolloClient.connectSocket = (userId, accountId, cb = () => {}) => {
    const mobileApi = store?.getState()?.domain?.api;
    const mobileSocket = store?.getState()?.domain?.socket;

    if (mobileApi) {
      API_URL = mobileApi;
    }

    if (mobileSocket) {
      absintheLink.setUri(mobileSocket);
    }
    absintheLink.setUserId(userId);
    absintheLink.setAccountId(accountId);
    absintheLink.connect(store?.getState()?.domain?.api, cb);

    store.dispatch({
      type: "SET_SOCKET_CONNECTION_TIMESTAMP",
      socketConnectionTimestamp: Date.now(),
    });

    return absintheLink;
  };

  apolloClient.disconnectSocket = () => {
    // prevent reconnect snackbar from firing on logout
    absintheLink.disconnect(true);
  };

  apolloClient.addOpenThreadSubscription = (groupId) => {
    activeGroupIds.push(groupId);
    // startOpenThreadsSubscription(store, apolloClient, groupId);
  };

  absintheLink.onSocketOpen((initialConnect) => {
    const isReconnect = store?.getState()?.startup?.isReconnect;
    if (initialConnect === false || isReconnect) {
      if (isMobile === false) {
        const i18nMsg = i18n.t(
          "signin-createApolloClient-connectionReestablished"
        );

        store.dispatch({ type: "CLOSE_SNACKBAR" });
        store.dispatch({
          type: "OPEN_SNACKBAR",
          snackMessage: i18nMsg,
          snackType: "success",
        });
      }
      apolloClient.resetStore();
    }

    // commented this out because I don't think we need it any longer? Keeping here in case we do.
    // const threadsActiveGroupId = store.getState().session.threadsActiveGroupId;
    // activeGroupIds.push(threadsActiveGroupId);

    store.dispatch({
      type: "SET_SOCKET_CONNECTION_TIMESTAMP",
      socketConnectionTimestamp: Date.now(),
    });
  });

  absintheLink.onPresenceJoin((user) => {
    const settings = {
      userId: user.id,
      username: user.username,
      contactId: user.contact_id,
      accountId: user.account_id,
      groupIds: user.group_ids,
      defaultGroupId: user.default_group_id,
      accountPolicies: user.account_policies.map((policy) => ({
        accountId: policy.account_id,
        actions: policy.actions,
      })),
      groupPolicies: user.group_policies.map((policy) => ({
        groupId: policy.group_id,
        actions: policy.actions,
      })),
      firstName: user.first_name,
      lastName: user.last_name,
      role: user.role,
      announcementsAccess: user.announcements_access,
      notificationInterval: user.notification_interval,
      notificationSound: user.notification_sound,
      notificationSettingsLocked: user.notification_settings_locked,
      groupInboxNotificationsEnabled: user.group_inbox_notifications_enabled,
      emailNotificationsEnabled: user.email_notifications_enabled,
      limitEmailNotifications: user.limit_email_notifications,
      emailNotificationDelayMins: user.email_notification_delay_mins,
      unclaimedThreadEmailNotificationsEnabled:
        user.unclaimed_thread_email_notifications_enabled,
      unreadThreadEmailNotificationsEnabled:
        user.unread_thread_email_notifications_enabled,
      unclaimedThreadMobileNotificationsEnabled:
        user.unclaimed_thread_mobile_notifications_enabled,
      unreadThreadMobileNotificationsEnabled:
        user.unread_thread_mobile_notifications_enabled,
      stockMessages: [
        { label: user.stock_messages[0], key: "1" },
        { label: user.stock_messages[1], key: "2" },
        { label: user.stock_messages[2], key: "3" },
      ],
      language: user.language,
      title: user.title,
      avatarUrl: user.avatar_url,
      theme: user.theme,
      fontSize: user.font_size,
      transferThreadNotification: user.transfer_thread_notification,
    };
    store.dispatch(authSignIn(settings));
    store.dispatch(
      updateApplicationState({
        userLoadedFromDistributor: true,
        userLoadTime: Date.now(),
      })
    );

    // Setup initial subscriptions.
    startCustomerAccountSubscription(store, apolloClient);
    startCustomerContactSubscription(store, apolloClient);
    startUserCreatedOrUpdatedSubscription(store, apolloClient);
    startClosedThreadsSubscription(store, apolloClient);
    startGlobalSnackbarSubscription(store, apolloClient);
  });

  absintheLink.onSocketClose(() => {
    if (!absintheLink._isSignOut && isMobile === false) {
      const i18nMsg = i18n.t("signin-createApolloClient-tryingToConnect");
      store.dispatch({
        type: "OPEN_SNACKBAR",
        snackMessage: i18nMsg,
        snackType: "error",
        snackAutoHide: false,
      });
    }
  });

  absintheLink.onAuthError(() => {
    absintheLink.disconnect();
    store.dispatch(signOut());
    store.dispatch(resetStore());

    if (account?.ff_sso && account?.ff_slo_uri) {
      globalWindow.group.href = account.ff_slo_uri;
    }
  });

  return apolloClient;
};

export default createApolloClient;
