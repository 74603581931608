import theme from 'styled-theming';
import COLORS from '../../colors';

const BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GREY_20,
});

const HIGHLIGHTED_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLACK,
  light: COLORS.PK.GREY_80,
  dark: COLORS.PK.GREY_80,
  holiday: COLORS.PK.GREY_80,
});

const REGION_BACKGROUND = theme('mode', {
  classic: COLORS.PK.GREEN_20,
  light: COLORS.PK.GREEN_20,
  dark: COLORS.PK.GREEN_60,
  holiday: COLORS.PK.GREEN_20,
});

const REGION_TEXT = theme('mode', {
  classic: COLORS.PK.GREY_90,
  light: COLORS.PK.GREY_90,
  dark: COLORS.PK.GREY_90,
  holiday: COLORS.PK.GREY_90,
});

const AI_BACKGROUND = theme('mode', {
  classic: COLORS.PK.BLUE_10,
  light: COLORS.PK.BLUE_10,
  dark: COLORS.PK.BLUE_100,
  holiday: COLORS.PK.BLUE_10,
});

const AI_BORDER = theme('mode', {
  classic: COLORS.PK.BLUE_40,
  light: COLORS.PK.BLUE_40,
  dark: COLORS.PK.BLUE_80,
  holiday: COLORS.PK.BLUE_40,
});

const TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.GREY_90,
  light: COLORS.PK.GREY_90,
  dark: COLORS.PK.WHITE,
  holiday: COLORS.PK.GREY_90,
});

const THEMES = {
  BACKGROUND,
  TEXT,
  HIGHLIGHTED_TEXT,
  REGION_BACKGROUND,
  REGION_TEXT,
  AI_BACKGROUND,
  AI_BORDER,
};

export default THEMES;
