import {
  contactName,
  parseCorrectAccountInfo,
} from 'client-lib/src/lib/utils/helpers';
import i18n from 'i18n-js';
import React from 'react';
import styled from 'styled-components';

import { EntityCard, Loading } from '../../../elements';
import THEMES from '../../../styles/themes/app';
import type {
  CustomerAccount,
  CustomerContact,
} from '../../../utils/helpers/types';
import Flyout from '../../Flyout2/Flyout';
import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll';
import { LoadingWrapper } from '../../Settings/styles';

const customContainerStyle = () => `
  border-bottom: 0px;
`;

const BroadcastContactContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 76px;
  width: 100%;
`;

const EntityCardContainer = styled.div`
  div {
    padding: unset;
  }
`;

const InfiniteScrollContainer = styled.div`
  flex: 1;
  width: 100%;
`;

export interface BulkCreateContactsFlyoutProps {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  isOpen: boolean;
  loading: boolean;
  paginatedContactList: (CustomerAccount | CustomerContact)[];
  fetchMoreContacts?: () => void;
  onClose?: () => void;
}

const BulkCreateContactsFlyout = ({
  hasNextPage,
  hasPreviousPage,
  isOpen,
  loading,
  paginatedContactList,
  fetchMoreContacts = () => {},
  onClose = () => {},
}: BulkCreateContactsFlyoutProps): JSX.Element => {
  return (
    <Flyout
      buttonActions={{
        secondary: onClose,
      }}
      buttonLabels={{
        secondary: i18n.t('broadcasts-ReachableAudienceModal-close'),
      }}
      customStyles={{ content: () => `padding-bottom: unset;` }}
      dataTestId="task-bulk-create-contacts-flyout"
      isOpen={isOpen}
      onRequestClose={onClose}
      title={i18n.t('tasks-bulkCreate-selectedContacts', {
        defaultValue: 'Selected Contacts',
      })}
    >
      {loading && !paginatedContactList?.length ? (
        <LoadingWrapper>
          <Loading size="sm" />
        </LoadingWrapper>
      ) : (
        <InfiniteScrollContainer>
          <InfiniteScroll
            dataTestId="broadcast-recipient-list"
            bidirectionalScroll={false}
            height="100%"
            scrollableList={paginatedContactList}
            renderRow={({ list, index }: { [key: string]: unknown }) => {
              const contactList = list as (CustomerAccount | CustomerContact)[];
              const contactIndex = index as number;
              const contact = contactList[contactIndex];

              if (!contact) return null;

              return (
                <BroadcastContactContainer>
                  <EntityCardContainer>
                    <EntityCard
                      avatarChildren={contactName(contact)}
                      maintext={contactName(contact)}
                      subtext={parseCorrectAccountInfo(contact)}
                      customContainerStyle={customContainerStyle}
                    />
                  </EntityCardContainer>
                </BroadcastContactContainer>
              );
            }}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            listItemHeight={77}
            loadingHeight={77}
            loadMoreRows={fetchMoreContacts}
            loading={loading}
          />
        </InfiniteScrollContainer>
      )}
    </Flyout>
  );
};

export default BulkCreateContactsFlyout;
