import i18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { URL_ARG_ENTITY_TYPES } from 'client-lib';
import {
  TableHead,
  Table,
  Button,
  useMultiSelectTableItems,
} from '../../../../elements';
import CommonTableRow from './subComponents/CommonTableRow';
import {
  FlexCenterContainer,
  EmptyListHeader,
  MediumHeader,
  SmallHeader,
  FlexBetweenContainer,
} from '../sharedStyles.ts';
import AudienceFilterSort from './subComponents/AudienceFilterSort';
import { getSortOptions, getOptInStatusText } from '../utils';
import InfiniteScroll from '../../../InfiniteScroll/InfiniteScroll';
import THEMES from '../../../../styles/themes/app';
import type { AppState, PageInfo } from '../../../../utils/helpers/types';
import type { SelectedAudience } from '../../groupMessage/types';

interface SelectedAudienceTableProps {
  filters: object;
  loading: boolean;
  loadMoreRows: () => void;
  onFilter: () => void;
  onRemove: (args: {
    companyIds: string[];
    contactIds: string[];
    groupIds: string[];
    labelIds: string[];
    tagIds: string[];
    remove: boolean;
  }) => void;
  onSort: () => void;
  pageInfo: PageInfo;
  selectedAudience: SelectedAudience[];
  sort: { label: string; value: string };
  heightDifference: number;
  disableCheckboxes: boolean;
}

const SelectedAudienceTable = ({
  filters,
  loading,
  loadMoreRows,
  onFilter,
  onRemove,
  onSort,
  pageInfo,
  selectedAudience,
  sort,
  heightDifference = 354,
  disableCheckboxes = false,
}: SelectedAudienceTableProps) => {
  const [companyIds, setCompanyIds] = useState<string[]>([]);
  const [contactIds, setContactIds] = useState<string[]>([]);
  const [labelIds, setLabelIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [tagIds, setTagIds] = useState<string[]>([]);

  const sortOptions = getSortOptions(i18n);

  const hasLabelsFF = useSelector(
    (state: AppState) => state.accountData.account?.ff_contact_labels
  );

  const {
    handleCheckItem,
    selectedItems,
    handleSelectNone,
    allItemsSelected,
    shouldCheckItem,
  } = useMultiSelectTableItems({
    items: selectedAudience,
  });

  // Splits up selected ids by object type
  const handleCheck = (id: string, type: string) => {
    const newContacts = [...contactIds];
    const newCompanies = [...companyIds];
    const newGroups = [...groupIds];
    const newLabels = [...labelIds];
    const newTags = [...tagIds];

    switch (type) {
      case URL_ARG_ENTITY_TYPES.CONTACT:
        if (shouldCheckItem(id)) {
          newContacts.splice(
            newContacts.findIndex((c) => c === id),
            1
          );
        } else {
          newContacts.push(id);
        }
        break;
      case URL_ARG_ENTITY_TYPES.COMPANY:
        if (shouldCheckItem(id)) {
          newCompanies.splice(
            newCompanies.findIndex((c) => c === id),
            1
          );
        } else {
          newCompanies.push(id);
        }
        break;
      case URL_ARG_ENTITY_TYPES.GROUP:
        if (shouldCheckItem(id)) {
          newGroups.splice(
            newGroups.findIndex((g) => g === id),
            1
          );
        } else {
          newGroups.push(id);
        }
        break;
      case URL_ARG_ENTITY_TYPES.LABEL:
        if (shouldCheckItem(id)) {
          newLabels.splice(
            newLabels.findIndex((l) => l === id),
            1
          );
        } else {
          newLabels.push(id);
        }
        break;
      case URL_ARG_ENTITY_TYPES.LIST:
        if (shouldCheckItem(id)) {
          newTags.splice(
            newTags.findIndex((l) => l === id),
            1
          );
        } else {
          newTags.push(id);
        }
        break;
      default:
        break;
    }
    handleCheckItem(id);
    setContactIds(newContacts);
    setCompanyIds(newCompanies);
    setGroupIds(newGroups);
    setLabelIds(newLabels);
    setTagIds(newTags);
  };

  useEffect(() => {
    if (allItemsSelected) {
      const newContacts: string[] = [];
      const newCompanies: string[] = [];
      const newGroups: string[] = [];
      const newLabels: string[] = [];
      const newTags: string[] = [];

      selectedAudience.forEach((item) => {
        if (item.type === URL_ARG_ENTITY_TYPES.CONTACT) {
          newContacts.push(item.id);
        } else if (item.type === URL_ARG_ENTITY_TYPES.GROUP) {
          newGroups.push(item.id);
        } else if (item.type === URL_ARG_ENTITY_TYPES.LABEL) {
          newLabels.push(item.id);
        } else if (item.type === URL_ARG_ENTITY_TYPES.LIST) {
          newTags.push(item.id);
        } else newCompanies.push(item.id);
      });

      setContactIds(newContacts);
      setCompanyIds(newCompanies);
      setGroupIds(newGroups);
      setLabelIds(newLabels);
      setTagIds(newTags);
    }
  }, [allItemsSelected, selectedAudience]);

  // Adds selected Ids to list and then resets selection
  const handleRemove = () => {
    onRemove({
      companyIds,
      contactIds,
      groupIds,
      labelIds,
      tagIds,
      remove: true,
    });
    setCompanyIds([]);
    setContactIds([]);
    setGroupIds([]);
    setLabelIds([]);
    setTagIds([]);
    handleSelectNone();
  };

  return (
    <div>
      <FlexBetweenContainer>
        <AudienceFilterSort
          hasFilter
          filters={filters}
          sortBy={sort}
          sortOptions={sortOptions}
          handleSubmitFilter={onFilter}
          handleSubmitSort={onSort}
        />
        <Button
          onClick={() => handleRemove()}
          disabled={selectedItems.length === 0 || disableCheckboxes}
        >
          {i18n.t('broadcasts-broadcast-removeSelected', {
            defaultValue: 'Remove %{number} Selected',
            number: selectedItems.length,
          })}
        </Button>
      </FlexBetweenContainer>
      <Table>
        <TableHead>
          <tr>
            <MediumHeader>
              {i18n.t('settings-FinalMessage-customer', {
                defaultValue: 'contact',
              })}
            </MediumHeader>
            <SmallHeader>
              {i18n.t('settings-Groups-groups', { defaultValue: 'groups' })}
            </SmallHeader>
            <SmallHeader>
              {i18n.t('settings-Label-labels', { defaultValue: 'labels' })}
            </SmallHeader>
            <SmallHeader>
              {i18n.t('broadcasts-broadcast-optin', {
                defaultValue: 'Opt-in Status',
              })}
            </SmallHeader>
          </tr>
        </TableHead>
      </Table>
      <InfiniteScroll
        dataTestId="broadcast-recipient-list"
        bidirectionalScroll={false}
        height={`calc(100vh - ${heightDifference}px)`}
        scrollableList={selectedAudience}
        noRowsElement={
          <FlexCenterContainer>
            <EmptyListHeader dataTestId="create-broadcastList-empty">
              {i18n.t('slideouts-TargetedAudience-noResults', {
                defaultValue:
                  'No results were found for this type of audience.',
              })}
            </EmptyListHeader>
          </FlexCenterContainer>
        }
        renderRow={(args: { list: SelectedAudience[]; index: number }) => {
          const { list, index } = args;
          const contact = list[index] as SelectedAudience;
          return (
            <CommonTableRow
              key={contact.id}
              id={contact.id}
              checked={shouldCheckItem(contact.id)}
              name={contact.name}
              type={contact.type}
              company={contact.companyName}
              groupIds={contact.groupIds}
              labels={contact.labels}
              color={contact.color}
              onCheck={() => handleCheck(contact.id, contact.type)}
              optInStatusText={getOptInStatusText(contact, i18n)}
              disabled={disableCheckboxes}
              hasLabelsFF={hasLabelsFF}
            />
          );
        }}
        hasNextPage={pageInfo.hasNextPage}
        hasPreviousPage={pageInfo.hasPreviousPage}
        listItemHeight={64}
        loadingHeight={64}
        loadingBackgroundColor={THEMES.BACKGROUND_PRIMARY}
        loadingBorderBottom
        loadMoreRows={loadMoreRows}
        loading={loading}
      />
    </div>
  );
};

export default SelectedAudienceTable;
