import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { useApolloClient } from '@apollo/client';
import {
  usePaginated,
  ANNOUNCEMENT_REACHABLE_AUDIENCE_QUERY,
} from 'client-lib';
import Flyout from '../../Flyout2/Flyout.tsx';
import { Loading } from '../../../elements/index.ts';
import BroadcastContactList from '../DetailsFlyout/BroadcastContactList.tsx';
import BroadcastSaveAsListFlyout from './BroadcastsSaveAsListFlyout';
import { LoadingWrapper } from '../../Settings/styles';

const BroadcastReachableAudienceFlyout = ({
  isOpen,
  setIsOpen,
  announcementId,
}) => {
  const client = useApolloClient();
  const [savingList, setSavingList] = React.useState(false);

  // Broadcast Report Data
  const { handleFetchMore, reachableAudience, loading, pageInfo } =
    usePaginated({
      client,
      query: ANNOUNCEMENT_REACHABLE_AUDIENCE_QUERY,
      queryVariables: {
        announcementId,
      },
      resultsNumber: 30,
      key: 'reachableAudience',
    });

  const handleRequestClose = () => {
    setIsOpen(false);
  };

  const handleSaveList = () => {
    setSavingList(true);
  };

  return (
    <>
      <Flyout
        isOpen={isOpen && !savingList}
        title={i18n.t('slideouts-GroupMessageRecipients-reachable')}
        onRequestClose={handleRequestClose}
        dataTestId="broadcast-table-flyout"
        buttonLabels={{
          primary: i18n.t('broadcasts-ReachableAudienceModal-saveList'),
          secondary: i18n.t('broadcasts-ReachableAudienceModal-close', {
            defaultValue: 'Close',
          }),
        }}
        buttonActions={{
          primary: handleSaveList,
          secondary: handleRequestClose,
        }}
        customStyles={{ content: () => `padding: unset; width: unset;` }}
      >
        {loading && !reachableAudience ? (
          <LoadingWrapper>
            <Loading size="sm" />
          </LoadingWrapper>
        ) : (
          <BroadcastContactList
            loadMoreRows={handleFetchMore}
            edges={
              reachableAudience?.map((contact) => ({
                node: { contact },
              })) || []
            }
            hasNextPage={pageInfo.hasNextPage}
            hasPreviousPage={pageInfo.hasPreviousPage}
          />
        )}
      </Flyout>
      <BroadcastSaveAsListFlyout
        isOpen={savingList}
        setIsOpen={setSavingList}
        announcementId={announcementId}
      />
    </>
  );
};

BroadcastReachableAudienceFlyout.propTypes = {
  announcementId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default BroadcastReachableAudienceFlyout;
