import styled from 'styled-components';
import COLORS from '../../../styles/colors';
import { Heading2 } from '../../../elements';
import THEMES from '../../../styles/themes/app';

export const FlexCenterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FlexBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;
`;

export const EmptyListHeader = styled(Heading2)`
  color: ${COLORS.PK.GREY_70};
  font-weight: 500;
  padding-top: 54px;
`;

export const MediumHeader = styled.th`
  width: 31%;
`;

export const SmallHeader = styled.th`
  width: 23%;
`;

export const StyledTableRow = styled.div`
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-left: 1px solid ${THEMES.BORDER_COLOR};
  border-right: 1px solid ${THEMES.BORDER_COLOR};
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  background: ${THEMES.BACKGROUND_PRIMARY};
  height: 63px;
`;

export const StyledCheckboxTD = styled.div<{ flexPercent?: number }>`
  display: flex;
  align-items: center;
  height: 61px !important;
  flex: 0 1 ${(props) => props.flexPercent || '31'}%;
  padding: 1px 16px;
  overflow: hidden;
`;

export const StyledTD = styled.div`
  display: flex;
  flex: 0 1 23%;
  padding: 1px 16px;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 61px;
  margin-left: 8px;
  overflow: hidden;
`;

export const ContactDataWrapper = styled.div`
  margin-left: 8px;
  display: inline;
  overflow: hidden;
`;

export const getStyledNameText = () => 'font-size: 16px;';

export const getStyledNameTextEllipsis = () => `
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const getStyledCompanyText = () => 'font-size: 14px; font-weight: 400;';

export const getStyledStatusText = () => `
  font-size: 14px;
  overflow: hidden;
  max-height: 61px;
`;
