import React from 'react';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { useGetLinkHost, type ApolloClientLib } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import { canPreviewBasedOnFileExtension } from 'client-lib/src/lib/utils/helpers';
import { useSelector } from 'react-redux';
import { EmphasisText, Heading2, Text } from '../../../../../elements';
import { ANNOUNCEMENT_TYPES } from '../../GroupMessageWizard';
import type { AppState } from '../../../../../utils/helpers/types';
import MessagePreview from '../../../../Common/MessagePreview';
import AttachmentView from '../../../../FileUpload/AttachmentView';

const Container = styled.div`
  width: 100%;
  position: relative;
  margin: auto;
  padding: 40px;
  box-sizing: border-box;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 8px;
  min-width: 250px;
`;

const SubheaderWrapper = styled.div`
  margin-bottom: 24px;
  min-width: 250px;
`;

const AttachmentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const TextWrapper = styled.div`
  padding-bottom: 4px;
  word-break: break-word;
`;

const AttachmentLinkMessage = () => (
  <div>
    <br />
    <Text>{'https://<link-will-go-here>'}</Text>
  </div>
);

export interface GroupMessagePreviewProps {
  messageText?: string;
  loading?: boolean;
  attachment?: {
    id: string;
    originalFilename: string;
  };
  accountName?: string;
  sent?: boolean;
  sendAttachmentAsLink?: boolean;
  attachmentBiggerThanPreview?: boolean;
  link?: string;
  announcementType?: string;
  announcementId: string;
}

const GroupMessagePreview = ({
  messageText,
  loading = false,
  attachment,
  accountName,
  sent,
  sendAttachmentAsLink,
  attachmentBiggerThanPreview,
  link,
  announcementType,
  announcementId,
}: GroupMessagePreviewProps) => {
  const client = useApolloClient() as unknown as ApolloClientLib;
  const { data } = useGetLinkHost({ client, id: announcementId });
  const canPreviewFile =
    attachment && canPreviewBasedOnFileExtension(attachment?.originalFilename);
  const promoAccess = useSelector(
    (state: AppState) =>
      state.accountData.account?.ff_broadcast_list_improvements
  );

  const hostLink = data?.announcement?.announcement?.linkHostName;

  const optOutText =
    announcementType === ANNOUNCEMENT_TYPES.PROMOTIONAL && promoAccess
      ? 'Reply NO PROMO for no promotions or STOP to stop all messages'
      : 'Text STOP to opt out';
  return (
    <Container>
      <HeaderWrapper>
        <Heading2>{i18n.t('slideouts-GroupMessageContent-preview')}</Heading2>
      </HeaderWrapper>
      <SubheaderWrapper>
        {sent ? (
          <Text>{i18n.t('slideouts-GroupMessageContent-saw')}</Text>
        ) : (
          <Text>{i18n.t('slideouts-GroupMessageContent-willSee')}</Text>
        )}
      </SubheaderWrapper>
      <MessagePreview loading={loading}>
        <>
          <TextWrapper>
            <Text>{`${accountName}:`}</Text>
          </TextWrapper>
          <TextWrapper>
            <EmphasisText>{messageText}</EmphasisText>
            {link !== '' && <Text contrast="highColor">{`\n${hostLink}`}</Text>}
          </TextWrapper>
          {attachment &&
            (sendAttachmentAsLink ||
            !canPreviewFile ||
            attachmentBiggerThanPreview ? (
              <AttachmentLinkMessage />
            ) : (
              <AttachmentContainer>
                <AttachmentView attachment={attachment} />
              </AttachmentContainer>
            ))}
          <TextWrapper>
            <br />
            <Text>{optOutText}</Text>
          </TextWrapper>
        </>
      </MessagePreview>
    </Container>
  );
};

export default GroupMessagePreview;
