import { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import useStartFaxThreadMutation from "../../../api/mutations/useStartFaxThreadMutation";
import useUploadAttachment from "../attachment/useUploadAttachment";
import { formatPhoneNumber } from "../../../utils/helpers";

/*

DOCUMENTATION
  this hook is created to be used with CreateFaxThread

  args:
    client: (obj) required. Apollo Client instance.
    threadsActiveGroupIds: (arr) required. the id of the users active group (from where the text/thread will be sent).
    onMutationStart: (func) optional. will be called before mutation fires.
    onMutationSuccess: (func) optional. will be called on mutation success.
    onMutationError: (func) optional. will be called on mutation error.
    groups: (arr) required. Groups array that comes from the state manager.

  returns:
    handleMutationStartThread: (func) mutation to start new thread.
    contact: (obj) state. holds currently selected contact (who the new thread will be sent to)
    setContact: (func) updates contact state.
    contactPhoneNumber: (str) state. used in the case of texting a number directly, as opposed to selecting a pre-known contact.
    setContactPhoneNumber: (func) updates contactPhoneNumber.
    senderGroup: (obj) state. this controlls who the "from" group will be, if the user has more than one group as active.
    setSenderGroup: (func) updates senderGroup.
    groupOptions: (obj) these are the groups that the user has selected as active, they have been restructured to { value, label }. Meant to be used with "select" elements.
    attachment: (obj) state. holds any attachment that will be sent with the new thread.
    setAttachment: (func) updates attachment state.
*/
const useCreateFaxThread = ({
  client,
  threadsActiveGroupIds,
  onMutationStart = () => {},
  onMutationSuccess = () => {},
  onMutationError = () => {},
  initialThread = null,
  onError = () => {}, // more generic, meant to communicate with user.
  channelsData,
}) => {
  const [handleStartFaxThread] = useStartFaxThreadMutation({ client });

  const groupOptions = channelsData
    ? channelsData.channels
        ?.filter((channel) => channel.faxConfig !== null)
        ?.filter((channel) =>
          threadsActiveGroupIds.some(
            (activeId) => activeId === channel.group.id
          )
        )
        ?.map((channel) => ({
          value: channel.group.id,
          label: `${channel.group.name}\u00A0 \u00A0${formatPhoneNumber(
            channel.group.faxNumber
          )}`,
        }))
    : [];

  // Id value of contact that new thread is being sent to
  const [contact, setContact] = useState(
    initialThread ? initialThread.contact : null
  );

  const [contactFaxNumber, setContactFaxNumber] = useState(
    initialThread ? initialThread.contactPhoneNumber : ""
  );

  const defaultSenderGroup =
    groupOptions?.length === 1
      ? groupOptions[0]
      : groupOptions?.find(
          (option) => option.value === threadsActiveGroupIds[0]
        );
  const [senderGroup, setSenderGroup] = useState(
    initialThread?.senderGroup ? initialThread.senderGroup : defaultSenderGroup
  );

  const [attachment, setAttachment] = useState(
    initialThread ? initialThread.attachment : null
  );

  const [tags, setTags] = useState([]);

  const uploadAttachment = useUploadAttachment({ client });

  useEffect(() => {
    if (!initialThread?.senderGroup)
      if (groupOptions?.length === 1) {
        setSenderGroup(groupOptions[0]);
      } else {
        setSenderGroup(
          groupOptions?.find(
            (option) => option.value === threadsActiveGroupIds[0]
          )
        );
      }
  }, [groupOptions.length]);

  useEffect(() => {
    if (senderGroup?.value === "") {
      setSenderGroup(defaultSenderGroup);
    }
  }, []);

  const handleMutationStartThread = debounce(
    async () => {
      onMutationStart();

      let attachments = [];
      if (attachment) {
        const uploadedAttachment = await uploadAttachment(attachment).catch(
          (err) => {
            console.error(err);
            onError(err);
          }
        );
        attachments = [{ url: uploadedAttachment?.url, method: "EMBED" }];
      }

      const startThreadArgs = {
        message: {
          attachments,
        },
        onSuccess: onMutationSuccess,
        onError: onMutationError,
      };

      if (tags.length !== 0) {
        startThreadArgs.tags = tags.map((tag) => tag.referenceNumber);
      }

      if (contact?.__typename === "CustomerContact") {
        startThreadArgs.senderGroupId =
          threadsActiveGroupIds.length > 1
            ? senderGroup?.value
            : threadsActiveGroupIds[0];
        startThreadArgs.externalContactId =
          contact && contact.id ? contact.id : null;
        startThreadArgs.externalFaxNumber = contactFaxNumber;
      } else if (!contact && contactFaxNumber) {
        startThreadArgs.senderGroupId =
          threadsActiveGroupIds.length > 1
            ? senderGroup?.value
            : threadsActiveGroupIds[0];
        startThreadArgs.externalFaxNumber = contactFaxNumber.replace(
          /[^0-9]/g,
          ""
        );
      }

      handleStartFaxThread(startThreadArgs);
    },
    1000,
    { leading: true }
  );

  return {
    handleMutationStartThread,
    contact,
    setContact,
    contactFaxNumber,
    setContactFaxNumber,
    senderGroup,
    setSenderGroup,
    groupOptions,
    setAttachment,
    attachment,
    tags,
    setTags,
  };
};

export default useCreateFaxThread;
