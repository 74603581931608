import React from 'react';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { Text, Tooltip } from '../../../elements';
import type { Recipient } from './types';

const customErrorStyle = () => `
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 158px;
`;

const ErrorCodeContainer = styled.div`
  display: flex;
  flex-grow: 2;
  flex-direction: row;
  justify-content: flex-end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ErrorCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ToolTipContainer = styled.div`
  max-width: 150px;
  text-wrap: wrap;
`;

const tooltipContent = (code: string) => {
  return (
    <ToolTipContainer>
      <Text customStyle={() => `word-wrap: break-word`}>
        {i18n.t(`broadcasts-errorCode-${code}`, {
          defaultValue: 'N/A',
        })}
      </Text>
    </ToolTipContainer>
  );
};

interface BroadcastListFailureCodesProps {
  recipient: Recipient;
}

const BroadcastListFailureCodes = ({
  recipient,
}: BroadcastListFailureCodesProps) => {
  return (
    <ErrorCodeContainer>
      <ErrorCodeWrapper>
        <Tooltip
          place="bottom"
          id={`${recipient?.node?.contact?.id}-tip`}
          elementsContent={tooltipContent(recipient?.node?.failureCode)}
        >
          <Text
            data-for={`${recipient?.node?.contact?.id}-tip`}
            data-tip
            customStyle={customErrorStyle}
            contrast="low"
          >
            {i18n.t(`broadcasts-errorCode-${recipient?.node?.failureCode}`, {
              defaultValue: 'N/A',
            })}
          </Text>
        </Tooltip>
      </ErrorCodeWrapper>
    </ErrorCodeContainer>
  );
};

export default BroadcastListFailureCodes;
