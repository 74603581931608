import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  CONTACTS_QUERY,
  useAccountQuery,
  usePaginated,
  GET_TASK_QUERY,
  type ApolloClientLib,
} from 'client-lib';

import { Loading } from '../../../elements';
import {
  closeCreateSectionModal,
  setActiveLoseProgressState,
  setActiveSidebar,
} from '../../../actions/general';
import { clearCreateSection } from '../../../actions/createSection';

import type { AppState } from '../../../utils/helpers/types';
import Wizard from '../index.tsx';
import CreateAndEditTask from '../../Tasks/CreateAndEditTask.tsx';
import TaskAudienceBuilder from './pages/TaskAudienceBuilder.tsx';
import type { TaskFormProps, TaskWizardGlobalProps } from './types.ts';
import {
  handleClearSelectedAudience,
  handleSaveAudience,
} from './helpers/utils.tsx';

const TaskWizardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const LoadingWrapper = styled.div`
  min-width: 400px;
  max-width: 960px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface SelectedAudience {
  contactIds: string[];
  groupIds: string[];
  companyIds: string[];
  labelIds: string[];
  tagIds: string[];
}

interface HandleSaveAudienceParams {
  companyIds?: string[];
  contactIds?: string[];
  groupIds?: string[];
  labelIds?: string[];
  tagIds?: string[];
  remove?: boolean;
}

const TaskWizard: React.FC = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedAudience, setSelectedAudience] = useState<SelectedAudience>({
    contactIds: [],
    groupIds: [],
    companyIds: [],
    labelIds: [],
    tagIds: [],
  });
  const [previousAudience, setPreviousAudience] =
    useState<SelectedAudience | null>(null);
  const [search, setSearch] = useState('');
  const [taskIsSubmitting, setTaskIsSubmitting] = useState(false);
  const [formProps, setFormProps] = useState<TaskFormProps>({
    fields: undefined,
    onSubmit: () => {},
    isVisitedForm: false,
    updateField: () => {},
  });

  const handleFormReady = useCallback((props: TaskFormProps) => {
    setFormProps((prevProps: TaskFormProps) => {
      if (JSON.stringify(prevProps) !== JSON.stringify(props)) {
        return props;
      }
      return prevProps;
    });
  }, []);

  const taskId = useSelector(
    (state: AppState) => state?.createSection?.createTask?.id
  );

  const allGroups = useSelector(
    (state: AppState) => state?.accountData?.allGroups
  );

  const currentUser = useSelector(
    (state: AppState) => state?.session?.currentUser
  );

  const { data: accountData } = useAccountQuery({
    client,
    variables: { id: currentUser?.accountId },
    skip: !currentUser,
  });

  const {
    called: tasksCalled,
    data: taskData,
    loading: taskLoading,
  } = useQuery(GET_TASK_QUERY, {
    client,
    variables: { id: taskId },
    fetchPolicy: 'network-only',
    skip: !taskId,
  });

  const closeModal = useCallback(() => {
    dispatch(setActiveLoseProgressState(false));
    dispatch(closeCreateSectionModal());
    dispatch(clearCreateSection());
    dispatch(setActiveSidebar('default'));
    history.push('/tasks');
  }, [dispatch, history]);

  const memoizedTaskData = useMemo(() => taskData?.task?.task, [taskData]);
  const memoizedGroups = useMemo(() => allGroups, [allGroups]);

  const shouldRenderForm = useMemo(() => {
    if (taskId) {
      return !taskLoading && tasksCalled;
    }
    return true;
  }, [taskId, taskLoading, tasksCalled]);

  // Audience management
  const { contacts, handleFetchMore, loading, pageInfo, extraParameters } =
    usePaginated({
      client: client as unknown as ApolloClientLib,
      key: 'contacts',
      query: CONTACTS_QUERY,
      queryVariables: {
        groupIds: selectedAudience.groupIds,
        labelIds: selectedAudience.labelIds,
        contactIds: selectedAudience.contactIds,
        companyIds: selectedAudience.companyIds,
        tagIds: selectedAudience.tagIds,
        audienceBuilder: true,
      },
    });

  const contactsForBulkTaskCreate = extraParameters?.contact_ids;

  const handleClearSelectedAudienceCallback = useCallback(() => {
    setSelectedAudience(handleClearSelectedAudience(previousAudience));
  }, [previousAudience]);

  const selectedAudienceCount = pageInfo?.totalCount || 0;

  const handleSaveAudienceCallback = useCallback(
    (params: HandleSaveAudienceParams) => {
      setSelectedAudience((prev) => handleSaveAudience(prev, params));
    },
    []
  );

  const handleSaveAndExit = useCallback((audience: SelectedAudience) => {
    setPreviousAudience(audience);
  }, []);

  const wizardGlobalProps: TaskWizardGlobalProps = useMemo(
    () => ({
      closeModal,
      taskData: memoizedTaskData,
      groups: memoizedGroups,
      taskId,
      taskIsSubmitting,
      setTaskIsSubmitting,
      onFormReady: handleFormReady,
      accountData,
      selectedAudience,
      paginatedContactList: contacts,
      contactsLoading: loading,
      handleFetchMoreContacts: handleFetchMore,
      contactPageInfo: pageInfo,
      selectedAudienceCount,
      contactsForBulkTaskCreate,
      search,
      setSearch,
      handleAudienceSelected: handleSaveAudienceCallback,
      taskRecipient: memoizedTaskData?.recipient,
      templateMessage: memoizedTaskData?.template?.message,
      isEditingAudience: !!previousAudience,
      handleSaveAndExit,
      setSelectedAudience,
      ...formProps,
    }),
    [
      closeModal,
      memoizedTaskData,
      memoizedGroups,
      taskId,
      taskIsSubmitting,
      handleFormReady,
      accountData,
      selectedAudience,
      contacts,
      loading,
      handleFetchMore,
      pageInfo,
      selectedAudienceCount,
      contactsForBulkTaskCreate,
      search,
      handleSaveAudienceCallback,
      previousAudience,
      handleSaveAndExit,
      formProps,
    ]
  );

  if (!shouldRenderForm) {
    return (
      <TaskWizardContainer data-testid="task-wizard">
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      </TaskWizardContainer>
    );
  }

  return (
    <TaskWizardContainer data-testid="task-wizard">
      <Wizard<TaskWizardGlobalProps>
        pages={{
          CreateAndEditTask: {
            start: true,
            component: CreateAndEditTask,
          },
          TaskAudience: {
            start: false,
            component: TaskAudienceBuilder,
          },
        }}
        wizardGlobalProps={wizardGlobalProps}
        closeWizard={handleClearSelectedAudienceCallback}
        setWizardPageOverride={() => {}}
      />
    </TaskWizardContainer>
  );
};

export default TaskWizard;
