import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 24px;
  margin-left: 24px;
  margin-right: 24px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
`;

export const customInputStyle = () => `width: 336px`;
