import {
  SET_CREATE_CUSTOMER_FIELD,
  SET_CREATE_CUSTOMER_FIELDS,
  CLEAR_CREATE_SECTION,
  SET_CREATE_CUSTOMER,
  SET_CREATE_THREAD,
  SET_CREATE_INTERNAL,
  SET_CREATE_FAX_THREAD,
  SET_CREATE_EMAIL,
  SET_CREATE_TASK,
} from '../actions/createSection';

const DEFAULT_STATE = {
  createCustomer: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    faxNumber: '',
    email: '',
    welcomeMessage: false,
    company: {
      value: '',
      text: '',
      addCompanySuggestion: false,
    },
    accountPhoneNumber: '',
    accountEmail: '',
    accountNumber: '',
    group: {
      value: [],
      text: '',
    },
    accountRep: {
      value: '',
      id: '',
      lastSelectedName: '',
      selectedUser: {},
    },
    prioritizeRep: false,
    updateContact: false,
    updateContactType: '',
    isMergeContact: false,
    newPhoneNumber: '',
    newFaxNumber: '',
    newEmail: '',
    mergeOn: '',
    mergeContactId: '',
    phoneSelectValue: '',
    faxSelectValue: '',
    emailSelectValue: '',
    groupIds: [],
  },
  createThread: {
    contact: null,
    messageInputValue: '',
    contactPhoneNumber: '',
    attachment: null,
    senderGroup: null,
    additionalRecipients: [],
    taskId: '',
    templateId: '',
  },
  createInternal: {
    contact: null,
    contactPhoneNumber: '',
    messageInputValue: '',
    attachments: [],
  },
  createFax: {
    contact: null,
    contactFaxNumber: '',
    attachment: null,
    senderGroup: null,
  },
  createEmail: {
    contact: null,
    messageInputValue: '',
    messageSubjectValue: '',
    carbonCopyContacts: [],
    contactEmail: '',
    attachments: [],
    senderGroup: null,
    taskId: '',
    templateId: '',
  },
  createTask: {
    id: '',
  },
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_CREATE_CUSTOMER_FIELD:
      return {
        ...state,
        createCustomer: {
          ...state.createCustomer,
          [action.key]: action.value,
        },
      };
    case SET_CREATE_CUSTOMER_FIELDS:
      return {
        ...state,
        createCustomer: {
          ...state.createCustomer,
          ...action.value,
        },
      };
    case SET_CREATE_CUSTOMER:
      return {
        ...state,
        createCustomer: action.value,
      };

    case SET_CREATE_THREAD:
      return {
        ...state,
        createThread: action.value,
      };
    case SET_CREATE_INTERNAL:
      return {
        ...state,
        createInternal: action.value,
      };
    case SET_CREATE_FAX_THREAD: {
      return {
        ...state,
        createFaxThread: action.value,
      };
    }
    case SET_CREATE_EMAIL: {
      return {
        ...state,
        createEmail: action.value,
      };
    }
    case SET_CREATE_TASK: {
      return {
        ...state,
        createTask: action.value,
      };
    }
    case CLEAR_CREATE_SECTION:
      return DEFAULT_STATE;

    default:
      return state;
  }
}
