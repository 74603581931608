import {
  $createParagraphNode,
  $getRoot,
  $getSelection,
  $insertNodes,
  type LexicalEditor,
  type LexicalNode,
} from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';
import { InsertType } from './constants';

interface LexicalDeserializationOptions {
  editor: LexicalEditor;
  htmlString: string;
  insertType: InsertType;
  mimeType?: DOMParserSupportedType;
}

/**
 * Deserializes an HTML string into Lexical nodes and inserts them into the editor.
 *
 * @param {LexicalDeserializationOptions} options - The options for deserialization.
 * @param {LexicalEditor} options.editor - The Lexical editor instance.
 * @param {string} options.htmlString - The HTML string to deserialize.
 * @param {InsertType} options.insertType - The type of insert operation to perform. Default behavior is to append the nodes at the end of the editor.
 * @param {DOMParserSupportedType} [options.mimeType='text/html'] - The MIME type of the HTML string.
 *
 * @returns {void}
 */
export default function lexicalDeserialization({
  editor,
  htmlString,
  insertType,
  mimeType = 'text/html',
}: LexicalDeserializationOptions): void {
  editor.update(() => {
    // In the browser you can use the native DOMParser API to parse the HTML string.
    const parser = new DOMParser();
    const dom = parser.parseFromString(htmlString, mimeType);

    // Once you have the DOM instance it's easy to generate LexicalNodes.
    const nodes = $generateNodesFromDOM(editor, dom);

    if (insertType === InsertType.replaceNodes) {
      // Remove all the nodes from the editor.
      $getRoot().clear();
    }

    if (
      insertType === InsertType.insertAtSelection ||
      insertType === InsertType.replaceNodes
    ) {
      // Get the current selection.
      $getSelection();
      // Insert the nodes at the current selection.
      $insertNodes(nodes);
    } else {
      // Create an array to hold only valid element nodes (like paragraphs)
      // This is crucial because text nodes cannot be appended directly to the root.
      const validNodes: LexicalNode[] = nodes.reduce(
        (acc: LexicalNode[], node) => {
          if (node.__type === 'text') {
            // If it's a text node, wrap it in a paragraph node
            const paragraphNode = $createParagraphNode();
            paragraphNode.append(node);
            acc.push(paragraphNode);
          } else {
            // If the node is a valid element node, add it to the list
            acc.push(node);
          }
          return acc;
        },
        []
      );
      // Append the nodes at the end of the editor.
      $getRoot().append(...validNodes);

      if (insertType === InsertType.appendNodesAndRemoveFirstChild) {
        // Remove the first child node (usually a paragraph node)
        $getRoot().getChildAtIndex(0)?.remove();
      }
    }
  });
}
