import type {
  FormValue,
  FormValueLabel,
  UiLabel,
  Validation,
} from "../../../utils/helpers/types";

interface CustomerAccount {
  __typename: string;
  id: string;
  name: string;
}

export interface TaskCustomerContactDb {
  __typename: string;
  account: CustomerAccount | null;
  emailAddress: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
}

interface TaskFormValuesBase {
  description: string;
  dueDate: string;
  name: string;
  id?: string;
}

export interface FormValueRecipient {
  value: TaskCustomerContactDb | string[] | null;
  validations?: Validation[];
  error?: string;
}

export interface TaskForm {
  description: FormValue;
  dueDate: FormValue;
  group: FormValueLabel;
  assignee: FormValueLabel;
  name: FormValue;
  recipient: FormValueRecipient;
  id?: FormValue;
  template: FormValueLabel;
  channelType: FormValue;
}

export interface TaskFormValues extends TaskFormValuesBase {
  assignee: UiLabel | null;
  group: UiLabel | null;
  recipient: TaskCustomerContactDb | null;
  template: { name: string; id: string } | null;
  channelType: string | null;
}

export interface TaskFormValuesFromDb extends TaskFormValuesBase {
  groupId: string;
  recipient: TaskCustomerContactDb;
  assignee: {
    id: string;
    firstName: string | null;
    lastName: string | null;
    avatarUrl: string | null;
  } | null;
  template: {
    name: string;
    id: string;
  } | null;
  channelType: string | null;
}

export interface TaskFormValuesToDb extends TaskFormValuesBase {
  groupId: string;
  recipientId?: string;
  recipientIds?: string[];
  assignee?: {
    id: string;
    firstName: string | null;
    lastName: string | null;
    avatarUrl: string | null;
  } | null;
  assigneeId?: string | null;
  templateId?: string | null;
  channelType?: string | null;
}

export interface Task {
  __typename?: string;
  assignee?: {
    __typename?: string;
    id: string;
    firstName: string | null;
    lastName: string | null;
    avatarUrl: string | null;
  } | null;
  channelType: string | null;
  completedAt: string | null;
  customerResponded?: boolean;
  description: string | null;
  dueDate: string;
  formattedDueDate?: string;
  groupId: string;
  id: string;
  insertedAt: string;
  insertedByUser: {
    __typename?: string;
    id: string | null;
  };
  lastUpdatedByUser: string | null;
  name: string;
  recipient: TaskCustomerContactDb | null;
  resultingMessage?: {
    __typename?: string;
    id: string;
    threadId: string;
  } | null;
  template: {
    __typename?: string;
    id: string;
    name?: string;
    message?: string;
  } | null;
  updatedAt: string | null;
}

export enum TaskView {
  ALL = "ALL",
  CLOSED = "CLOSED",
  // TODO: (Tasks) Do we need another query view?
  // CREATED_BY_ME = 'CREATED_BY_ME',
  OPEN = "OPEN",
  UNCLAIMED = "UNCLAIMED",
}

export interface InitialTaskData {
  bulkRecipientIds?: string[];
}
