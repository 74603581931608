import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { useDispatch, useSelector } from 'react-redux';
import { SEARCH_CUSTOMERS_QUERY, useCreatePaymentRecipient } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import {
  emailRegex,
  normalizePhone,
  phoneRegex,
} from 'client-lib/src/lib/utils/validation';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import {
  Button,
  ButtonSelector,
  Heading2,
  Heading5,
  InfiniteSuggest,
  InputError,
  Select,
  SelectedEntity,
} from '../../../../elements';
import AddEmailAddress from '../../../Modals/AddEmailAddress';
import THEMES from '../../../../styles/themes/library/button';
import { openSnackbar } from '../../../../actions/general';
import AddPhoneNumber from '../../../Modals/AddPhoneNumber';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 50px;
  height: 90%;
  max-height: 90%;
`;
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  max-width: 600px;
  align-items: stretch;
  justify-content: ${(props) =>
    props.centerVertically ? 'center' : 'flex-start'};
`;

const HeadingWrap = styled.div`
  margin-bottom: 48px;
`;

const MessageTypeErrorRow = styled.div`
  padding: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
`;

const ButtonRow = styled.div`
  margin: 16px 0;
  display: flex;
  position: relative;
`;

const addButtonCustomStyles = (props) => `
  color: ${THEMES.LINK_TEXT(props)};
  height: 24px;
  padding: 0;
  :hover {
    color: ${THEMES.LINK_TEXT_HOVER(props)};
  }
`;

const CreatePaymentRecipient = ({
  wizardState,
  wizardStateSetters,
  setWizardPage,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [addActiveEmailModal, setAddActiveEmailModal] = useState(false);
  const [addEmailModalContact, setAddEmailModalContact] = useState(null);
  const [addActivePhoneNumberModal, setAddActivePhoneNumberModal] =
    useState(false);
  const [addPhoneNumberModalContact, setAddPhoneNumberModalContact] =
    useState(null);

  const currentUser = useSelector((state) => state?.session?.currentUser);
  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );
  const allGroups = useSelector((state) => state?.accountData?.allGroups);

  const updateField = wizardStateSetters?.updateField;
  const setSelectedToContact = wizardStateSetters?.setSelectedToContact;
  const selectedToContact = wizardState?.selectedToContact;
  const setSenderGroupId = wizardStateSetters.setSenderGroupId;

  const {
    groupOptions,
    handleUpdateToContact,
    handleMessageTypeChange,
    disableTextOption,
    disableEmailOption,
    disableContinueButton,
    fromContact,
    toContact,
    messageType,
    contactPhoneNumber,
    contactEmailAddress,
    contactText,
    setContactText,
    messageTypeError,
    // setMessageTypeError,
  } = useCreatePaymentRecipient({
    client,
    i18n,
    currentUser,
    allGroups,
    threadsActiveGroupIds,
    updateField,
    fields: wizardState?.fields,
  });

  useEffect(() => {
    if (
      disableEmailOption &&
      toContact?.value &&
      !toContact?.otherProps?.emailAddress &&
      !addEmailModalContact?.id &&
      selectedToContact?.id
    ) {
      setAddEmailModalContact(selectedToContact);
    } else if (
      disableTextOption &&
      toContact?.value &&
      !toContact?.otherProps?.phoneNumber &&
      !addPhoneNumberModalContact?.id &&
      selectedToContact?.id
    ) {
      setAddPhoneNumberModalContact(selectedToContact);
    }
  }, [disableTextOption, toContact?.id]);

  // this useEffect is strictly to get the from group Id
  // to the usePaymentWizard hook
  useEffect(() => {
    if (fromContact?.value) {
      setSenderGroupId(fromContact?.value);
    }
  }, [fromContact?.value]);

  const clearToFieldBubble = () => {
    setContactText('');
    handleUpdateToContact();
    setAddEmailModalContact(null);
    setAddPhoneNumberModalContact(null);
    setSelectedToContact(null);
  };

  const onSuggestionSelected = (e, { suggestion }) => {
    handleUpdateToContact(suggestion);
    setSelectedToContact(suggestion);
    // setContactText('');
    if (!suggestion?.emailAddress) {
      setAddEmailModalContact(suggestion);
    }
    if (!suggestion?.phoneNumber) {
      setAddPhoneNumberModalContact(suggestion);
    }
  };

  const certifyToInputValueOnBlur = (e) => {
    const trimmedValue = e.target.value.trim();

    if (emailRegex.test(trimmedValue) === true) {
      setContactText(trimmedValue);
      handleUpdateToContact({ emailAddress: trimmedValue });
    } else if (phoneRegex.test(trimmedValue) === true) {
      const phoneNumber = normalizePhone(trimmedValue);
      setContactText(phoneNumber);
      handleUpdateToContact({ phoneNumber });
    } else if (
      !toContact?.value ||
      contactPhoneNumber === '' ||
      contactEmailAddress === ''
    ) {
      handleUpdateToContact();
      setContactText('');
    }
  };

  const handleAddButtonClick = () => {
    if (addEmailModalContact?.id) return setAddActiveEmailModal(true);
    return setAddActivePhoneNumberModal(true);
  };

  const handleOnModalError = (err) => {
    clearToFieldBubble();
    setAddEmailModalContact(null);
    setAddPhoneNumberModalContact(null);
    setSelectedToContact(null);
    dispatch(openSnackbar(err, 'error'));
  };

  const options = [
    {
      value: 'all',
      label: i18n.t('settings-TeamPageContainer-all', { defaultValue: 'All' }),
      props: {
        disabled: disableTextOption || disableEmailOption,
        key: 'options-all',
      },
    },
    {
      value: 'sms',
      label: (
        <>
          <i className="ri-question-answer-line" />
          {i18n.t('reports-ChannelButtonSelector-text', {
            defaultValue: 'Text',
          })}
        </>
      ),
      props: {
        iconAndText: true,
        disabled: disableTextOption,
        key: 'options-sms',
      },
    },
    {
      value: 'email',
      label: (
        <>
          <i className="ri-mail-line" />
          {i18n.t('settings-ProfileForm-email', { defaultValue: 'Email' })}
        </>
      ),
      props: {
        iconAndText: true,
        disabled: disableEmailOption,
        key: 'options-email',
      },
    },
  ];

  return (
    <Container>
      <InnerContainer>
        <HeadingWrap>
          <Heading2 dataTestId="new-message-header">
            {i18n.t('slideouts-CreatePaymentRecipient-newPaymentLink', {
              defaultValue: 'New Collections Link',
            })}
          </Heading2>
        </HeadingWrap>

        <Select
          label={i18n.t('slideouts-CreateThread-fromLabel')}
          options={groupOptions}
          value={fromContact?.value}
          onChange={(option) =>
            updateField({
              name: 'fromContact',
              otherProps: { text: option?.label },
              value: option?.value,
            })
          }
        />
        {!contactPhoneNumber && !contactEmailAddress && !toContact?.value ? (
          <InfiniteSuggest
            inputProps={{
              autoFocus: true,
              label: i18n.t('slideouts-CreateThread-toLabel'),
              // error: optedOutError,
              onBlur: certifyToInputValueOnBlur,
              value: contactText,
              onChange: (e) => setContactText(e.target.value),
              placeholder: i18n.t('slideouts-CreateThread-enterNameOrNumber'),
            }}
            query={SEARCH_CUSTOMERS_QUERY}
            queryKey="searchCustomers"
            queryVariables={{
              query: contactText,
              after: null,
              typeFilter: 'CUSTOMER_CONTACT',
            }}
            onSelect={onSuggestionSelected}
            includeGroupIndicator
            isDisabled={(node) =>
              node?.conversationsOptOut && node?.emailsOptOut
            }
            rightAlignedText={(node) => {
              if (node?.conversationsOptOut && node?.emailsOptOut)
                return i18n.t('slideouts-GroupMessageRecipients-optedOut');
              if (node?.conversationsOptOut)
                return i18n.t('slideouts-CreatePaymentRecipient-smsOptedOut', {
                  defaultValue: 'Text Opted-Out',
                });
              if (node?.emailsOptOut)
                return i18n.t(
                  'slideouts-CreatePaymentRecipient-emailsOptedOut',
                  {
                    defaultValue: 'Email Opted-Out',
                  }
                );
              return undefined;
            }}
          />
        ) : (
          <SelectedEntity
            label={i18n.t('slideouts-CreateThread-toLabel')}
            avatarChildren={
              toContact?.value ? toContact?.otherProps?.text : undefined
            }
            maintext={toContact?.otherProps?.text}
            subtext={toContact?.otherProps?.accountName}
            onDelete={clearToFieldBubble}
          />
        )}
        <Heading5 style={{ margin: '8px 0px' }}>
          {i18n.t('slideouts-CreatePaymentRecipient-messageType', {
            defaultValue: 'Message Type',
          })}
        </Heading5>
        <ButtonSelector
          options={options}
          onChange={handleMessageTypeChange}
          selectedValues={[messageType?.otherProps?.text]}
        />
        {messageTypeError ? (
          <MessageTypeErrorRow>
            <InputError helperText={messageTypeError} />
            {(addEmailModalContact?.id || addPhoneNumberModalContact?.id) && (
              <Button
                type="noStyle"
                onClick={handleAddButtonClick}
                customStyle={addButtonCustomStyles}
                dataTestId="add-tag-button"
                noOutline
              >
                {addEmailModalContact?.id
                  ? i18n.t('modals-AddEmailAddress-addEmail', {
                      defaultValue: 'Add Email Address',
                    })
                  : i18n.t('modals-AddPhoneNumber-addPhone', {
                      defaultValue: 'Add Phone Number',
                    })}
              </Button>
            )}
          </MessageTypeErrorRow>
        ) : (
          <div style={{ height: '40px' }} />
        )}
        <ButtonRow>
          <Button
            onClick={() => setWizardPage('CreatePaymentMessage')}
            disabled={disableContinueButton}
          >
            {i18n.t('settings-withCustomerImportLayout-continue')}
          </Button>
        </ButtonRow>
      </InnerContainer>
      {addEmailModalContact?.id && (
        <AddEmailAddress
          open={addActiveEmailModal}
          onClose={() => {
            setAddActiveEmailModal(false);
          }}
          onSave={(contactEmail) => {
            setAddActiveEmailModal(false);
            setAddEmailModalContact(null);
            setSelectedToContact((prev) => ({
              ...prev,
              emailAddress: contactEmail,
            }));
            handleUpdateToContact({
              ...addEmailModalContact,
              emailAddress: contactEmail,
            });
            dispatch(
              openSnackbar(
                i18n.t('slideouts-CreateEmail-emailAdded', {
                  contactName: contactName(addEmailModalContact),
                  defaultValue: `Email address for ${contactName(
                    addEmailModalContact
                  )} has been added.`,
                })
              )
            );
          }}
          onError={handleOnModalError}
          contact={addEmailModalContact}
        />
      )}
      {addPhoneNumberModalContact?.id && (
        <AddPhoneNumber
          open={addActivePhoneNumberModal}
          onClose={() => {
            setAddActivePhoneNumberModal(false);
          }}
          onSave={(contactPhone) => {
            setAddActivePhoneNumberModal(false);
            setAddPhoneNumberModalContact(null);
            setSelectedToContact((prev) => ({
              ...prev,
              phoneNumber: contactPhone,
            }));
            handleUpdateToContact({
              ...addPhoneNumberModalContact,
              phoneNumber: contactPhone,
            });
            dispatch(
              openSnackbar(
                i18n.t('slideouts-CreatePhoneNumber-emailAdded', {
                  contactName: contactName(addPhoneNumberModalContact),
                  defaultValue: `Phone for ${contactName(
                    addPhoneNumberModalContact
                  )} has been added.`,
                })
              )
            );
          }}
          onError={handleOnModalError}
          contact={addPhoneNumberModalContact}
        />
      )}
    </Container>
  );
};

CreatePaymentRecipient.propTypes = {
  wizardState: PropTypes.object.isRequired,
  setWizardPage: PropTypes.func.isRequired,
  wizardStateSetters: PropTypes.object.isRequired,
};

export default CreatePaymentRecipient;
