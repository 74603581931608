import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {
  ThemeProvider as MuiThemeProvider,
  THEME_ID,
  createTheme,
} from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import Modal from 'react-modal';
import { useMultiGroupFeature, i18n as i18nTranslations } from 'client-lib';
import i18n from 'i18n-js';
import { getAvailableThemes } from 'client-lib/src/lib/utils/helpers';
import { Body, GlobalScrollbarStyle } from '../components/Common';
import { setThreadsActiveGroupIds } from '../actions/session';
import SignedInApp from '../components/SignedInApp/SignedInApp';
import Routes from '../Routes';

i18n.translations = {
  'en-US': { ...i18nTranslations.EN_US },
  'es-MX': { ...i18nTranslations.ES_MX },
  'fr-CA': { ...i18nTranslations.FR_CA },
};

const materialTheme = createTheme({});

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  const originalWarn = console.error;
  // eslint-disable-next-line no-console
  console.error = (...args) => {
    if (
      args?.[0]?.includes?.(
        'Support for defaultProps will be removed from function components in a future major release.'
      )
    ) {
      return;
    }
    originalWarn(...args);
  };
}

export const SignInContext = React.createContext({
  sessionLoading: true,
  setSessionLoading: () => {},
});

const App = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [sessionLoading, setSessionLoading] = useState(true);

  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );
  const theme = useSelector((state) => state?.session?.currentUser?.theme);
  const fontSize = useSelector(
    (state) => state?.session?.currentUser?.fontSize
  );
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const backupLanguage = useSelector((state) => state?.general?.backupLanguage);

  // where language and theme get set. Defaults provided if none on user object.
  const availableThemes = getAvailableThemes();
  const mode = availableThemes.includes(theme) ? theme : 'light';

  const locale = currentUser?.language || backupLanguage || 'EN_US';

  // this block of code will be used in the case of if a PK account downgrades from multi inbox to singlular inbox.
  // Will check for !multiGroup. If !multiGroup AND more than one active group id, than reset redux store value to have the first id in the old array.
  const { multiGroup } = useMultiGroupFeature({ client, currentUser });

  useEffect(() => {
    if (multiGroup === false && threadsActiveGroupIds.length > 1) {
      dispatch(setThreadsActiveGroupIds([threadsActiveGroupIds[0]]));
    }
  }, [multiGroup]);

  const formattedLocale =
    locale.slice(0, 2).toLowerCase() + '-' + locale.slice(3);
  i18n.locale = formattedLocale;
  i18n.fallbacks = 'en-US';

  const sessionLoadingContextValue = useMemo(
    () => ({
      sessionLoading,
      setSessionLoading,
    }),
    [sessionLoading]
  );
  return (
    <ThemeProvider
      theme={{ mode, fontSize: fontSize?.toLowerCase() || 'default' }}
    >
      <SignInContext.Provider value={sessionLoadingContextValue}>
        <MuiThemeProvider theme={{ [THEME_ID]: materialTheme }}>
          <BrowserRouter>
            <GlobalScrollbarStyle theme={{ mode }} />
            <Body data-testid="app">
              {currentUser?.userId ? <SignedInApp /> : <Routes />}
            </Body>
          </BrowserRouter>
        </MuiThemeProvider>
      </SignInContext.Provider>
    </ThemeProvider>
  );
};

export default App;
