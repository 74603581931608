import React from 'react';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { EmphasisText, InlineTextButton, Button } from '../index';
import Bubble from '../../components/Common/Bubble';
import THEMES from '../../styles/themes/app';

const FilterWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 16px;
`;

const FilterBubbleContainer = styled.div`
  position: relative;
  width: 0;
  z-index: 2;
`;

const FilterOptions = styled.div`
  padding: 16px;
`;

const Eyecon = styled.i`
  font-size: 20px !important;
  padding-right: 4px;
`;

const ChildrenWrapper = styled.div`
  padding-top: 16px;
`;

const ButtonFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CancelButtonWrapper = styled.div`
  padding-right: 8px;
`;

const ActiveFiltersBadge = styled.div`
  background-color: ${THEMES.COLORED_BACKGROUND_SECONDARY};
  border-radius: 4px;
  width: 24px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
`;

const getInlineTextStyles = (): string => `
  border-width: 0px !important;
  color: ${(props: { disabled: boolean }) =>
    props.disabled ? THEMES.FOREGROUND_LOW(props) : 'inherit'}
  &:hover {
    text-decoration: underline !important;
    color: ${(props: { disabled: boolean }) =>
      props.disabled ? THEMES.FOREGROUND_LOW(props) : 'inherit'}
`;

interface FilterByProps {
  activeFiltersNum: number;
  children: React.ReactNode;
  clearAllFilters: () => void;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  setIsOpen: (isOpen: boolean) => void;
  bubbleProps?: object | null;
  dataTestId?: string;
  disableClear?: boolean;
  disableSubmit?: boolean;
}

// A unique dataTestId is suggested for each SortBy component (use Parent Component name)
const FilterBy = ({
  activeFiltersNum,
  children,
  clearAllFilters,
  isOpen,
  onCancel,
  onSubmit,
  setIsOpen,
  bubbleProps = null,
  dataTestId = 'FilterBy',
  disableClear = false,
  disableSubmit = false,
}: FilterByProps) => {
  return (
    <>
      <FilterWrapper>
        <InlineTextButton
          noOutline
          onClick={() => setIsOpen(true)}
          customStyle={(props: object) =>
            `color: ${
              isOpen || activeFiltersNum > 0
                ? THEMES.FOREGROUND_HIGH_COLOR(props)
                : THEMES.FOREGROUND_HIGH(props)
            };
            &:hover{
              color: ${
                isOpen || activeFiltersNum > 0
                  ? THEMES.FOREGROUND_MED_COLOR(props)
                  : THEMES.FOREGROUND_HIGH_COLOR(props)
              }
            }`
          }
          dataTestId={`${dataTestId}-filter-by-open-button`}
        >
          <Eyecon className="ri-filter-3-line" />
          {i18n.t('customers-FilterBy-filterBy', { defaultValue: 'Filter By' })}
        </InlineTextButton>
        {activeFiltersNum > 0 && (
          <ActiveFiltersBadge>
            <EmphasisText
              customStyle={() => `line-height: 14px;`}
              contrast="colorHigh"
              dataTestId={`${dataTestId}-filter-by-active-filters-badge`}
            >
              {activeFiltersNum}
            </EmphasisText>
          </ActiveFiltersBadge>
        )}
      </FilterWrapper>
      <FilterBubbleContainer>
        <Bubble
          isOpen={isOpen}
          hasCarrot={false}
          onClickOutside={(ev) => {
            if (
              ev?.target?.parentNode?.parentNode
                ?.getAttribute('class')
                ?.includes('Select__clear') ||
              ev?.target?.parentNode
                ?.getAttribute('class')
                ?.includes('Select__clear')
            ) {
              // do nothing, we are just changing sort directions
            } else {
              onCancel();
            }
          }}
          moveBubbleRightVal={290}
          moveLeftVal={-50}
          bubbleMinWidth={360}
          top="4px"
          fixedPosition
          dataTestId={`${dataTestId}-filter-by-bubble`}
          {...bubbleProps}
        >
          <FilterOptions>
            <InlineTextButton
              noOutline
              onClick={clearAllFilters}
              disabled={disableClear}
              customStyle={getInlineTextStyles}
              dataTestId={`${dataTestId}-filter-by-clear-all`}
            >
              {i18n.t('customers-FilterBy-clearAll', {
                defaultValue: 'Clear All Filters',
              })}
            </InlineTextButton>
            <ChildrenWrapper>{children}</ChildrenWrapper>
            <ButtonFooter>
              <CancelButtonWrapper>
                <Button
                  onClick={() => {
                    setIsOpen(false);
                    onCancel();
                  }}
                  type="tertiary"
                  dataTestId={`${dataTestId}-filter-by-cancel`}
                >
                  {i18n.t('modals-DeleteTeamMember-cancel', {
                    defaultValue: 'Cancel',
                  })}
                </Button>
              </CancelButtonWrapper>
              <Button
                onClick={onSubmit}
                disabled={disableSubmit}
                dataTestId={`${dataTestId}-filter-by-apply`}
              >
                {i18n.t('customers-FilterBy-apply', { defaultValue: 'Apply' })}
              </Button>
            </ButtonFooter>
          </FilterOptions>
        </Bubble>
      </FilterBubbleContainer>
    </>
  );
};

export default FilterBy;
