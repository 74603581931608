import type { SelectedAudience, HandleSaveAudienceParams } from '../types';

export const handleSaveAudience = (
  prev: SelectedAudience,
  params: HandleSaveAudienceParams
): SelectedAudience => {
  const {
    companyIds = [],
    contactIds = [],
    groupIds = [],
    labelIds = [],
    tagIds = [],
    remove = false,
  } = params;

  if (!remove) {
    return {
      contactIds: [...new Set([...prev.contactIds, ...contactIds])],
      groupIds: [...new Set([...prev.groupIds, ...groupIds])],
      companyIds: [...new Set([...prev.companyIds, ...companyIds])],
      labelIds: [...new Set([...prev.labelIds, ...labelIds])],
      tagIds: [...new Set([...prev.tagIds, ...tagIds])],
    };
  }

  return {
    contactIds: prev.contactIds.filter((id) => !contactIds.includes(id)),
    groupIds: prev.groupIds.filter((id) => !groupIds.includes(id)),
    companyIds: prev.companyIds.filter((id) => !companyIds.includes(id)),
    labelIds: prev.labelIds.filter((id) => !labelIds.includes(id)),
    tagIds: prev.tagIds.filter((id) => !tagIds.includes(id)),
  };
};

export const handleClearSelectedAudience = (
  previousAudience: SelectedAudience | null
): SelectedAudience => {
  if (previousAudience) {
    return previousAudience;
  }

  return {
    contactIds: [],
    groupIds: [],
    companyIds: [],
    labelIds: [],
    tagIds: [],
  };
};

export const shouldRenderForm = (
  taskId: string | undefined,
  taskLoading: boolean,
  tasksCalled: boolean
): boolean => {
  if (taskId) {
    return !taskLoading && tasksCalled;
  }
  return true;
};
