import React from 'react';
import i18n from 'i18n-js';
import styled from 'styled-components';
import { useQuery, useApolloClient } from '@apollo/client';
import {
  ANNOUNCEMENT_RECURRING_QUERY,
  SIMPLE_ANNOUNCEMENT_QUERY,
  usePaginated,
  type ApolloClientLib,
} from 'client-lib';
import { useSelector } from 'react-redux';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import {
  Card,
  Text,
  Heading5,
  Heading3,
  InlineTextButton,
  Loading,
} from '../../../elements';
import Flyout from '../../Flyout2/Flyout';
import { dateOptions, timeOptions } from '../../../utils/helpers/constants';
import type { AppState, Announcement } from '../../../utils/helpers/types';

type DateProp = {
  __typename: string;
  date: string;
};

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 300px);
  justify-content: center;
  align-items: center;
`;

const SmallLoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;
`;

const SummaryContainer = styled.div`
  display: flex;
  row-gap: 8px;
  flex-direction: column;
  padding-top: 16px;
`;

const UpcomingSummaryContainer = styled.div`
  display: flex;
  row-gap: 8px;
  flex-direction: column;
`;

const getStyledHeading = () => `
  height: 44px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const getFontSize = () => `font-size: 12px;`;

const getTopPadding = () => `padding-top: 8px;`;

export interface BroadcastRecurringFlyoutProps {
  isOpen: boolean;
  recurringBroadcast: Partial<Announcement>;
  onCloseRecurringFlyout: () => void;
  handleEdit: () => void;
}

const BroadcastRecurringFlyout = ({
  isOpen,
  recurringBroadcast,
  onCloseRecurringFlyout,
  handleEdit,
}: BroadcastRecurringFlyoutProps) => {
  const locale = useSelector(
    (state: AppState) => state?.session?.currentUser?.language
  );
  const client = useApolloClient();
  const localePrefix = locale.substring(0, 2);

  const { data, loading } = useQuery(SIMPLE_ANNOUNCEMENT_QUERY, {
    client,
    variables: { id: recurringBroadcast?.id },
  });

  const {
    announcementRecurringSchedule,
    handleFetchMore,
    pageInfo,
    loading: recurLoading,
  } = usePaginated({
    client: client as unknown as ApolloClientLib,
    query: ANNOUNCEMENT_RECURRING_QUERY,
    queryVariables: {
      announcementId: recurringBroadcast?.id,
    },
    resultsNumber: 10,
    key: 'announcementRecurringSchedule',
  });

  const hasNext = pageInfo?.hasNextPage;

  const formatUpdatedDate = new Date(
    data?.announcement?.announcement?.updatedAt
  );

  const formatSendAtDate = new Date(data?.announcement?.announcement?.dueAt);

  const displayUpdateDate = formatUpdatedDate.toLocaleString(
    localePrefix,
    dateOptions
  );

  const displaySentAtDate = formatSendAtDate.toLocaleString(
    localePrefix,
    dateOptions
  );

  const displaySentAtTime = formatSendAtDate.toLocaleTimeString(
    localePrefix,
    timeOptions
  );

  const displayDay = formatSendAtDate.toLocaleString(localePrefix, {
    day: 'numeric',
  });

  const recurringOption = data?.announcement?.announcement?.repeats;
  return (
    <Flyout
      dataTestId="broadcast-recurring-schedule-preview"
      title={i18n.t('broadcasts-recurring-title', {
        defaultValue: 'Recurring Broadcast / Broadcast Name',
        name: recurringBroadcast?.subject || '',
      })}
      buttonLabels={{
        secondary: i18n.t('broadcasts-ReachableAudienceModal-close'),
        title: 'ri-pencil-fill',
      }}
      buttonActions={{
        secondary: () => onCloseRecurringFlyout(),
        title: () => handleEdit(),
      }}
      isOpen={isOpen && Boolean(recurringBroadcast)}
      subtitle={i18n.t('broadcasts-recurring-createdSummary', {
        name: contactName(recurringBroadcast?.insertedByUser),
        date: displayUpdateDate,
      })}
      onRequestClose={() => onCloseRecurringFlyout()}
      buttonProps={{
        title: {
          contrast: 'highColor',
          size: 'md',
        },
      }}
    >
      {loading ? (
        <LoadingWrapper>
          <Loading size="sm" />
        </LoadingWrapper>
      ) : (
        <>
          <SummaryContainer>
            <Heading5 customStyle={getFontSize} type="noStyle">
              {i18n.t('broadcasts-recurring-summary', {
                defaultValue: 'summary',
              })}
            </Heading5>
            <Card type="quaternary">
              {recurringOption === 'WEEKLY' && (
                <Text>
                  {i18n.t('broadcasts-recurring-weekly', {
                    time: displaySentAtTime,
                    date: displaySentAtDate,
                  })}
                </Text>
              )}
              {recurringOption === 'DAILY' && (
                <Text>
                  {i18n.t('broadcasts-recurring-daily', {
                    time: displaySentAtTime,
                    date: displaySentAtDate,
                  })}
                </Text>
              )}
              {recurringOption === 'MONTHLY' && (
                <Text>
                  {i18n.t('broadcasts-recurring-monthly', {
                    day: displayDay,
                    date: displaySentAtDate,
                  })}
                </Text>
              )}
            </Card>
          </SummaryContainer>
          <UpcomingSummaryContainer>
            <Heading5 customStyle={getFontSize} type="noStyle">
              {i18n.t('broadcasts-recurring-upcomingSummary', {
                defaultValue: 'summary',
              })}
            </Heading5>
            {announcementRecurringSchedule?.map((cur: DateProp) => {
              const currentDate = new Date(cur?.date).toLocaleString(
                localePrefix,
                dateOptions
              );
              return (
                <Card key={cur?.date}>
                  <Heading3 customStyle={getStyledHeading}>
                    {currentDate}
                  </Heading3>
                </Card>
              );
            })}
            {hasNext && (
              <InlineTextButton
                customStyle={getTopPadding}
                onClick={handleFetchMore}
                noOutline
                textType="EmphasisText"
              >
                {i18n.t('search-Search-loadMore', {
                  defaultValue: 'Load More',
                })}
              </InlineTextButton>
            )}
            {recurLoading && (
              <SmallLoadingWrapper>
                <Loading size="sm" />
              </SmallLoadingWrapper>
            )}
          </UpcomingSummaryContainer>
        </>
      )}
    </Flyout>
  );
};

export default BroadcastRecurringFlyout;
