// CreateAndEditTask.tsx
import React, {
  useCallback,
  useEffect,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { useDispatch } from 'react-redux';
import { ApolloClient, useApolloClient } from '@apollo/client';
import i18n from 'i18n-js';
import { type ApolloClientLib } from 'client-lib';
import useUpsertTask from 'client-lib/src/lib/controllers/hooks/tasks/useUpsertTask';
import { Loading } from '../../elements';
import { LoadingWrapper } from '../Settings/styles';
import { openSnackbar } from '../../actions/general';
import UpsertTask from './UpsertTask';
import type { TaskWizardGlobalProps } from '../Wizard2/bulkTask/types';
import type { ContentProps } from '../Wizard2';
import { buildUpsertTaskResponse } from './helpers/utils';

const CreateAndEditTask: React.FC<ContentProps<TaskWizardGlobalProps>> = ({
  wizardGlobalProps,
  setWizardPage,
}) => {
  const client: ApolloClient<object> = useApolloClient();

  const dispatch = useDispatch();

  const {
    taskData,
    groups,
    taskId,
    closeModal,
    setTaskIsSubmitting,
    onFormReady,
    taskRecipient,
    templateMessage,
    fields,
    selectedAudienceCount,
    contactsForBulkTaskCreate,
  } = wizardGlobalProps;

  const handleSuccess = useCallback(() => {
    setTaskIsSubmitting(false);
    dispatch(
      openSnackbar(
        buildUpsertTaskResponse(i18n, selectedAudienceCount, !!taskId),
        'success'
      )
    );
    closeModal();
  }, [
    setTaskIsSubmitting,
    dispatch,
    selectedAudienceCount,
    taskId,
    closeModal,
  ]);

  const handleError = useCallback(() => {
    setTaskIsSubmitting(false);
    dispatch(
      openSnackbar(i18n.t('settings-ProfileFormContainer-error'), 'error')
    );
  }, [dispatch, setTaskIsSubmitting]);

  const handleSetIsSubmitting = useCallback(() => {
    setTaskIsSubmitting(true);
  }, [setTaskIsSubmitting]);

  const formProps = useUpsertTask({
    client: client as unknown as ApolloClientLib,
    taskData: taskId ? taskData : null,
    allGroups: groups,
    translatedRequiredMessage: i18n.t('slideouts-CreateCustomerForm-required'),
    i18n,
    handleOnSuccess: handleSuccess,
    handleOnError: handleError,
    setIsSubmitting: handleSetIsSubmitting,
    initialFields: fields,
    ...(contactsForBulkTaskCreate?.length && {
      initialTaskData: { bulkRecipientIds: contactsForBulkTaskCreate },
    }),
  });

  useEffect(() => {
    onFormReady({
      updateField: formProps.updateField,
      fields: formProps.fields,
      onSubmit: formProps.onSubmit,
      isVisitedForm: formProps.isVisitedForm,
    });
  }, [
    formProps.updateField,
    formProps.fields,
    formProps.onSubmit,
    formProps.isVisitedForm,
    onFormReady,
  ]);

  if (!fields) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  return (
    <UpsertTask
      taskRecipientFromApi={taskRecipient}
      templateMessageFromApi={templateMessage}
      setWizardPage={setWizardPage as Dispatch<SetStateAction<string>>}
      wizardGlobalProps={wizardGlobalProps}
    />
  );
};

export default CreateAndEditTask;
