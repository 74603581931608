import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  contactName,
  accountName,
  accountNumber,
} from 'client-lib/src/lib/utils/helpers';
import { useSelector } from 'react-redux';
import { EntityCard, IconButton } from '../../../elements';
import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll';
import THEMES from '../../../styles/themes/app';
import type {
  AppState,
  CustomerContact,
  Thread,
} from '../../../utils/helpers/types';
import {
  RightSideComponent,
  ViewTypeOptions,
  type Recipient,
  type RecipientDataEdge,
} from './types';
import BroadcastListFailureCodes from './BroadcastListFailureCodes';

const customTextStyle = (hasError: boolean) => `
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${hasError ? '200px' : '360px'};
`;

const customContainerStyle = () => `
  border-bottom: 0px;
`;

const InfiniteScrollContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const BroadcastContactContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 76px;
  width: calc(100% - 32px);
  padding: 0 16px;
`;

const EntityCardContainer = styled.div`
  div {
    padding: unset;
  }
`;

const FlexButton = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 2;
  margin-right: 3px;
`;

/**
 * Helper function for displaying subtext for contact entitycard
 * @param {object} entity - contact object
 * @returns string - account name and/or number
 */
const parseCorrectAccountInfo = (entity: CustomerContact) => {
  const name = accountName(entity);
  const number = accountNumber(entity);

  if (name && number) return `${name}\u00A0-\u00A0# ${number}`;
  if (name) return name;
  if (number) return number;

  return '';
};

type ListIndex = {
  list: Recipient[];
  index: number;
};

interface BroadcastContactListProps {
  loadMoreRows: () => void;
  setContactSearch?: (search: string) => void;
  rightComponent?: RightSideComponent;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  edges: RecipientDataEdge[];
  onViewUpdate: (view: ViewTypeOptions, thread: Thread) => void;
}

const BroadcastContactList = ({
  edges,
  hasNextPage,
  hasPreviousPage,
  loadMoreRows,
  rightComponent,
  setContactSearch = () => {},
  onViewUpdate,
}: BroadcastContactListProps) => {
  const replyViewAccess = useSelector(
    (state: AppState) =>
      state.accountData.account?.ff_broadcast_text_reply_routing
  );
  useEffect(() => {
    return () => {
      setContactSearch('');
    };
  }, [setContactSearch]);

  return (
    <InfiniteScrollContainer>
      <InfiniteScroll
        dataTestId="broadcast-recipient-list"
        bidirectionalScroll={false}
        height="100%"
        scrollableList={edges || []}
        renderRow={(props: ListIndex) => {
          const { list, index } = props;
          const recipient = list[index] as Recipient;
          return (
            <BroadcastContactContainer>
              <EntityCardContainer>
                <EntityCard
                  avatarChildren={contactName(recipient?.node?.contact)}
                  maintext={contactName(recipient?.node?.contact)}
                  subtext={parseCorrectAccountInfo(recipient?.node?.contact)}
                  customSubtextStyle={() => customTextStyle(!!rightComponent)}
                  customContainerStyle={customContainerStyle}
                  customMaintextStyle={() => customTextStyle(!!rightComponent)}
                />
              </EntityCardContainer>
              {rightComponent === RightSideComponent.FailureCode && (
                <BroadcastListFailureCodes recipient={recipient} />
              )}
              {rightComponent === RightSideComponent.ResponseView &&
                replyViewAccess &&
                recipient?.node?.responseThread && (
                  <FlexButton>
                    <IconButton
                      onClick={() =>
                        onViewUpdate(
                          ViewTypeOptions.ResponseMessage,
                          recipient?.node?.responseThread
                        )
                      }
                    >
                      <i className="ri-arrow-right-s-line" />
                    </IconButton>
                  </FlexButton>
                )}
            </BroadcastContactContainer>
          );
        }}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        listItemHeight={77}
        loadingHeight={77}
        loadMoreRows={loadMoreRows}
        loading={hasNextPage}
      />
    </InfiniteScrollContainer>
  );
};

export default BroadcastContactList;
